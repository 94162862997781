import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPodcastlistByGenre } from '../httpcalls/podcastThunk';
import { resetPodcastlistSlice, updatePodcastlist } from '../store/schemas/podcastlistSlice';
import Header from '../components/Header/header';
import H_Back from '../components/Header/h_back';
import H_Title from '../components/Header/h_title';
import H_Settings from '../components/Header/h_settings';


const PodcastsGet = () => {
  const dispatch = useDispatch()
	const { genre } =  useParams()
	const podcastlist = useSelector((state) => state.mainapp.podcastlistSlice.podcastlist)
  const podcasts = podcastlist.results
  const [offset, setOffset] = useState(0)
	const navigate = useNavigate()

	useEffect(() => {
		dispatch(getPodcastlistByGenre({genre}));

		return (() => {
			dispatch(resetPodcastlistSlice())
			window.scrollTo(0, 0)
		})
	}, [])

  const loadMoreResults = () => {
    const newOffset = offset + 10;
    setOffset(newOffset);
    dispatch(getPodcastlistByGenre({ genre:genre, offset: newOffset}));
  };

  return (
        <>
          	<Header topLeft={[<H_Back />, <H_Title title={genre}/>]} topRight={<H_Settings/>} />
              <div className='podcastlist-grid-container'>
                  {podcasts.map((podcast, index) => (
                  <div className='podcastlist-grid-item'>
                      <Link to={'/discover/podcast/'+ podcast.id}>
                          <img src={podcast.thumbnail}/>                        
                      </Link>
                      <div className='podcastlist-header-text'>
                        <h5>{podcast.title_original}</h5>
                        <p>{podcast.publisher_original}</p>          
                      </div>          
                  </div>
                  ))}
                  
              </div>
              <div className='load_more'>
                <button onClick={loadMoreResults}>Load More</button>
              </div>  
        </>
  );
}

export default PodcastsGet;