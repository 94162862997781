import React from 'react';
import Alli from '../assets/alli_purple.png'
import Header from '../components/Header/header';
import H_Back from '../components/Header/h_back';
import H_Title from '../components/Header/h_title';
import H_Settings from '../components/Header/h_settings';

const AboutPage = () => {
  return (
    <div className='about_body'>
    <Header topLeft={[<H_Back />, <H_Title title={'About Us'}/>]} topRight={<H_Settings/>} />
        <div className='about_container'>
            <img src={Alli} />
            <p>
                Welcome to Alli, a new media aggre<span>gator</span>! We are a podcast experience designed and built by podcast enthusiasts. We are passionate about bringing you the best podcasts from various genres.
            </p>
            <p>
                Our mission is to provide a seamless listening experience and help you discover new and exciting content with an evergrowing featureset that the podcast enthusiast really cares about!
            </p>
            <p>
                Feel free to explore our platform and enjoy a world of amazing podcasts.
            </p>
        </div>
    </div>
  );
}

export default AboutPage;