import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getPodcastById } from '../../httpcalls/podcastThunk';
import Header from '../../components/Header/header';
import H_Back from '../../components/Header/h_back';
import H_Title from '../../components/Header/h_title';
import DOMPurify from 'dompurify';
import P_Description from './p_description';
import { resetPodcastSlice } from '../../store/schemas/podcastSlice';
import { addAudio, resetAudioSlice } from '../../store/schemas/audioSlice';
import { useDisclosure } from '@mantine/hooks';
import { Input, Modal, ScrollArea} from '@mantine/core';
import { createPlaylistWithPodcast } from '../../httpcalls/playlistThunks';


const P_Create_Playlist = ({episode, close, podcastInfo}) => {
	const dispatch = useDispatch()

	

	const submitCreation = (e) => {
		e.preventDefault()
		dispatch(createPlaylistWithPodcast({episode:episode, playlistTitle:e.target.elements[0].value, podcastInfo:podcastInfo})).then(() => {
			close()
		})
	}


	

	return (


		<>
			<form onSubmit={(e) => {submitCreation(e)}}>
				<Input.Wrapper label="Playlist Name" size='lg'>
					<Input placeholder="My Amazing Playlist" size='md' radius={9} maxLength={30} />
				</Input.Wrapper>

				<button className='playlist-modal-action-button'> <i className='material-symbols-outlined'>library_add</i>  Confirm</button>
			</form>
		   
			
		
		</>
	);
};


export default P_Create_Playlist;
