import React from 'react';
import HeaderMenu from './headerMenu';

const Header = ({homepage, installPrompt, topLeft, topRight}) => {
    
    return (
        <header className='header'>
            {homepage === true ?
                <>
                    <div className='header_homepage'>
                        <HeaderMenu installPrompt={installPrompt}/>
                    </div>
                    <div className='header-right'> 
                        {topRight}
                    </div>
                </>
            :
                <>
                    <div  className='header-left'>
                        {topLeft}
                    </div>
                    <div className='header-right'> 
                        {topRight}
                    </div>
                </>
            } 
        </header>
    );
    
}

export default Header;
