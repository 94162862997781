import React, {useEffect, useState} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login/login';
import Podcasts from './pages/Podcasts/podcasts';
import PodcastList from './pages/podcastlist';
import Contact from './pages/contact';
import About from './pages/about';
import Search from './pages/Search/search';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from './httpcalls/authThunk';
import MainApp from './mainapp';
import Discovery from './pages/Discovery/discovery';
import Player from './pages/Player/player';
import Library from './pages/Library/library';
import LPlaylist from './pages/Library/l_playlist';
import TermsOfService from './pages/Login/termsofservice';
import PrivacyPolicy from './pages/Login/privacypolicy';
import Timeline from './pages/Timeline/timeline';
import SocialMedia from './pages/Login/socialMedia';
import CompactPlayer from './pages/Player/pl_compact';
import Profile from './pages/userAccount/Profile';


function App() {
  const user = useSelector(state => state.auth.userInfo)
  const dispatch = useDispatch()
  const [appLoaded, setAppLoaded] = useState(false)

  useEffect(() => {
      dispatch(getUser()).then(() => {
        setAppLoaded(true)
      })
  }, [])
  return (
    appLoaded ?
    <>
          <Routes>
            {user == undefined ?
              <>
                <Route path='/' element={<Login />} />
                <Route path='*' element={<Navigate to='/' /> } />
                <Route path='/termsofservice' element={<TermsOfService />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/social' element={<SocialMedia />} />
                <Route path='/about' element={<About />} />
              </>
              :
              <>
                <Route path='/' element={<MainApp/>}>
                    <Route path='/library' >
                      <Route path='/library' element={<Library />} />
                      <Route path='/library:playlistId' element={<LPlaylist />} />
                    </Route>

                    <Route path='/discover' >
                      <Route path='/discover' element={<Discovery />} />
                      <Route path='/discover/podcast/:id'  element={<Podcasts />} /> 
                      <Route path='/discover/podcastlist/:genre' element={<PodcastList />} />
                    </Route>

                    <Route path='/search'>
                      <Route path='/search' element={<Search />} />
                    </Route>

                    <Route path='/player' element={<Player />} />
                    <Route path='/' element={<Navigate to='/library' /> } />
                    <Route path='*' element={<Navigate to='/discover' /> } />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/about' element={<About />} /> 
                    <Route path='/timeline' element={<Timeline />} />
                    <Route path='/social' element={<SocialMedia />} />
                    <Route path='/profile' element={<Profile />} />
                  </Route>
              </>
            }

          </Routes>
          <CompactPlayer />

    </>
    :
    null
  );
}

export default App;
