import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { getEpisodeHistory } from '../../httpcalls/historyThunk';
import { resetHistorySlice } from '../../store/schemas/historySlice';
import { useSelector } from 'react-redux';



const UserHistory = () => {
    const dispatch = useDispatch();

    const history = useSelector((state) => state.mainapp.historySlice.history)

    useEffect(() => {
		dispatch(getEpisodeHistory());

		return (() => {
			dispatch(resetHistorySlice());
			window.scrollTo(0, 0);
		})
	}, [dispatch])

    return (
        <div className='discover-overflow'>
            {history.length === 0 ? <h1 className='subscription-empty'>No Subscriptions Found...</h1> :  
                history.map((podcast) => {
                    return (
                        <div className='library-row' key={podcast.id}>
                            <Link to={'/discover/podcast/' + podcast.listennotes_id} className='discover-overflow-item'>
                                    <img src={podcast.thumbnail} alt='' />
                                    <h5 style={{color: '#D84465'}}>{podcast.podcast_title}</h5>
                                    <p>{podcast.episode_title}</p>
                            </Link>
                        </div>
                    )
                })
            }
        </div>
    );
};

export default UserHistory;