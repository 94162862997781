import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Header from '../components/Header/header';
import H_Back from '../components/Header/h_back';
import H_Title from '../components/Header/h_title';
import H_Settings from '../components/Header/h_settings';

const Contact = () => {

	const form = useRef();
	const [isEmailSent, setIsEmailSent] = useState(false);
  
	const sendEmail = (e) => {
	  e.preventDefault();
  
	  emailjs
		.sendForm('service_3rtblds', 'template_n6y7b6b', form.current, 'kx3JyJppo7YNpskBE')
		.then(
		  (result) => {
			console.log(result.text);
			setIsEmailSent(true);
			setTimeout(() => {
			  setIsEmailSent(false);
			  form.current.reset(); // Reset the form after a successful submission
			}, 2000); // Clear the success message after 3 seconds
		  },
		  (error) => {
			console.log(error.text);
		  }
		);
	};

	return (
		<div>
			<Header topLeft={[<H_Back />, <H_Title title={'Contact Us'}/>]} topRight={<H_Settings/>} />
			<form className='contact_container' ref={form} onSubmit={sendEmail}>
				<h1>Contact Us!</h1>
				{isEmailSent && <p className='success-message'>Email sent successfully!</p>}
				<div>
						<label className='contact_label'>Name:</label>
						<input className='contact_input'
						type='text'
						name='name'

					/>
				</div>
				<div>
					<label className='contact_label'>Email:</label>
					<input className='contact_input'
						type='email'
						name='email'

					/>
				</div>
				<div>
					<label className='contact_label'>Message:</label>
					<textarea className='contact_textarea'
						name='message'

					/>
				</div>
				<button className='contact_submit_button' type='submit'>Submit</button>
			</form>
		</div>
	);
};
export default Contact;