import React from 'react';import Header from "../../components/Header/header";
import H_Title from '../../components/Header/h_title';
import H_Back from '../../components/Header/h_back';
import { Link } from 'react-router-dom';
import H_Settings from '../../components/Header/h_settings';

const TermsOfService = () => {
  return (
    <div className='terms_of_service'>
        <Header topLeft={[<H_Back />, <H_Title title={"Terms of Service"} />]} topRight={<H_Settings/>} />
        <div className='terms_of_service_container'>
            
            <div>
                <h2>
                    Effective Date: 11/06/2023
                </h2>
            </div>
            <div>
                <p>
                    Welcome to Alli.Media ("we," "our," or "us")! By using our podcast player app and website, you agree to comply with and be bound by the following Terms of Service. If you do not agree to these terms, please do not use our products or services.
                </p>
            </div>
            <div>
                <h3>
                    1. Use of Our Products and Services
                </h3>
                <p>
                    <span>a.</span> Eligibility: You must be at least 18 years old or the legal age of majority in your jurisdiction to use our services. By using our app and website, you represent and warrant that you meet these eligibility requirements. <br />
                    <span>b.</span> License: We grant you a limited, non-exclusive, non-transferable, and revocable license to use our app and website for personal, non-commercial purposes.
                </p>
            </div>
            <div>
                <h3>
                    2. User Content 
                </h3>
                <p>
                <span>a.</span> Submission: By submitting content, including but not limited to comments, feedback, and reviews, you grant us a worldwide, royalty-free, perpetual, irrevocable license to use, reproduce, modify, adapt, publish, translate, distribute, perform, and display such content. <br />
                <span>b.</span> Responsibility: You are solely responsible for the content you submit, and you agree not to submit any content that is unlawful, defamatory, harassing, or infringes on the rights of others.
                </p>
            </div>
            <div>
                <h3>
                    3. Prohibited Conduct
                </h3>
                <p>
                    You agree not to: <br />
                    <span>a.</span> Violate any applicable laws or regulations. <br />
                    <span>b.</span> Use our services for any unlawful or unauthorized purpose. <br />
                    <span>c.</span> Interfere with or disrupt the integrity or performance of our app and website. <br />
                    <span>d.</span> Attempt to gain unauthorized access to our systems or networks. <br />
                    <span>e.</span> Engage in any form of automated data collection, scraping, or extraction.
                </p>
            </div>
            <div>
                <h3>
                    4. Intellectual Property
                </h3>
                <p>
                    Ownership: All content, logos, trademarks, and other intellectual property on our app and website are owned by us or our licensors. You may not use, reproduce, or distribute our intellectual property without our express consent.
                </p>
            </div>
            <div>
                <h3>
                    5. Termination
                </h3>
                <p>
                    We reserve the right to suspend or terminate your access to our services at our sole discretion, without notice, for any violation of these Terms of Service or for any other reason.
                </p>
            </div>
            <div>
                <h3>
                    6. Limitation of Liability
                </h3>
                <p>
                    We are not liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your use of our products or services.
                </p>
            </div>
            <div>
                <h3>
                    7. Changes to Terms of Service
                </h3>
                <p>
                    We may update these Terms of Service from time to time. Any changes will be effective upon posting the updated terms on our website. Your continued use of our services after the effective date constitutes acceptance of the updated terms.
                </p>
            </div>
            <div>
                <h3>
                    8. Governing Law
                </h3>
                <p>
                    These Terms of Service are governed by and construed in accordance with the laws of Wyoming.
                </p>
            </div>
            <div>
                <h3>
                    9. Contact Us
                </h3>
                <p>
                    If you have any questions or concerns about these Terms of Service, please contact us <Link to='/contact'>here</Link>.
                </p>
            </div>
            <div className='terms_of_service_signoff'>
                <h4>
                    Thank you for using Alli.Media! We appreciate your compliance with these terms.
                </h4>
            </div>
        </div>
    </div>
  );
};

export default TermsOfService;