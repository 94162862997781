import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAudioPause, updateIsSleepTimerActive } from '../../store/schemas/audioSlice';
import { updateEpisodeTime } from '../../httpcalls/historyThunk';
import { useLocation, useNavigate } from 'react-router-dom';

const Pl_Compact = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const audioIndex = useSelector((state) => state.mainapp.audioSlice.activeAudioIndex);
	const audioItem = useSelector((state) => state.mainapp.audioSlice.audioList[audioIndex]);
	const pause = useSelector((state) => state.mainapp.audioSlice.pause);
	const audioPlayback = useSelector((state) => state.mainapp.audioSlice.audioPlayback);
	const audioPlaybackEnd = useSelector((state) => state.mainapp.audioSlice.audioPlaybackEnd);
    const sleepTimerDuration = useSelector((state) => state.mainapp.audioSlice.sleepTimerDuration);
    const isSleepTimerActive = useSelector((state) => state.mainapp.audioSlice.isSleepTimerActive);
	const [audioPlaybackControl, setAudioPlaybackControl] = useState(null);
    const currentPlayingId	= audioItem?.id;

	
	const barRef = useRef(null);
    function convertPlaylbackTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
        
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };
    
    // Adjust pause handling to ensure it doesn't directly trigger updates
    const handlePausePress = () => {
        dispatch(updateAudioPause(!pause));
        if (!pause) {
            // If we are pausing, dispatch update immediately
            dispatch(updateEpisodeTime({ listennotes_id: audioItem?.id, playback: audioPlayback }));
        }
    };

    // Ensure to update when the component unmounts or audio ends
    useEffect(() => {
        let timerId;

        if (isSleepTimerActive && sleepTimerDuration) {
            timerId = setTimeout(() => {
                // Pause or stop playback when timer runs out
                dispatch(updateAudioPause(true));
                dispatch(updateIsSleepTimerActive(false));
                dispatch(updateEpisodeTime({ listennotes_id: currentPlayingId, playback: audioPlayback }));
            }, sleepTimerDuration);
        }

        return () => clearTimeout(timerId);
    }, [isSleepTimerActive, sleepTimerDuration, dispatch, audioItem]);

	const calculatePointPosition = (e, duration) => {
        const touchX = e.touches[0].clientX;
        const bar = e.currentTarget;
        const barStart = bar.getBoundingClientRect().left;
        const barWidth = barRef.current.getBoundingClientRect().width
        const touchPosition = touchX - barStart;
        const touchPercentage = touchPosition / barWidth; 
        const newTime = Math.floor(touchPercentage * duration); 

        if(newTime >= duration){
            setAudioPlaybackControl(duration)
        }
        else if(newTime <= 0){
            setAudioPlaybackControl(0)
        }
        else{
            setAudioPlaybackControl(newTime)
        };
	};

	const calculateTimePosition = (time) => {
		if(barRef.current == null) return 0
        const width = barRef.current.getBoundingClientRect().width
       	return Math.round(time / audioPlaybackEnd *  width)
	};

	const handleIsPlayingClick = () => {
		navigate('/player');
	};

	// Don't display if there's not active audio
	if (audioItem == null || location.pathname === '/player') return null;

	return (
		<div className='compact-player'>
			<img onClick={handleIsPlayingClick} src={audioItem?.thumbnail} alt={audioItem?.title} />
			<div className='compact-player-info'>
				<span>{audioItem?.title}</span>
				<div className='player-bar'>
					<div className='player-default-bar'></div>
					<div ref={barRef} className='player-markerbox-bar'></div>

					<div className='player-moving-bar' style={audioPlaybackControl != null ? {width:calculateTimePosition(audioPlaybackControl) + 'px'} :  {width:calculateTimePosition(audioPlayback) + 'px'}}></div>
					<span style={audioPlaybackControl != null ? {left:calculateTimePosition(audioPlaybackControl) + 'px'} :  {left:calculateTimePosition(audioPlayback) + 'px'}}></span>
				</div>
				<div className='player-timers'>
				{
					audioPlaybackControl != null ?
					<p>{convertPlaylbackTime(audioPlaybackControl)}</p>
					:
					<p>{convertPlaylbackTime(audioPlayback)}</p>
				}
				<p>{convertPlaylbackTime(audioPlaybackEnd)}</p>
				</div>
			</div>
			<button onClick={handlePausePress} className='playpause'>
				<i className='material-icons'>{pause ? 'play_arrow' : 'pause'}</i>
			</button>
		</div>
	);
};


export default Pl_Compact;