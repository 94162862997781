import React from "react";

const H_Title = ({title}) => {
 
	return (
		<>
			<h4>{title}</h4>
		</>
	);
}

export default H_Title;
