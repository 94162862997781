import { createSlice } from "@reduxjs/toolkit";

const initialState = ({
    podcastlist:{
        count: 0,
        next_offset: 0,
        total: 0,
        results: [],
    },
});

const podcastlistSlice = createSlice({
    name: 'podcastlistSlice',
    initialState,
    reducers: {
        resetPodcastlistSlice: (state) => initialState,
        updatePodcastlist: (state, action) => {
            const {q, results} = action.payload;
            state.podcastlist = {
                q, results: state.podcastlist.results.concat(results),
            };
        },
    }
})

export const { resetPodcastlistSlice, updatePodcastlist } = podcastlistSlice.actions

export default podcastlistSlice.reducer