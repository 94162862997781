import { createAsyncThunk } from '@reduxjs/toolkit'
import { updateRecommendedRows } from '../store/schemas/discoverSlice'
import { updatePodcast } from '../store/schemas/podcastSlice'
import { updatePodcastlist} from '../store/schemas/podcastlistSlice'
// import { notifications } from '@mantine/notifications';

const server = process.env.REACT_APP_API_URL

export const discoverPodcasts = createAsyncThunk(
    'auth/discoverPodcasts',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/api/discoverPodcasts`, {
                method:'GET',
                credentials: 'include',
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            thunkApi.dispatch(updateRecommendedRows(results.message))
            return 'success'
        
        } catch (error) {
            console.log(error)
        }
    }
);

export const getPodcastById = createAsyncThunk(
    'auth/getPodcastById',
    async ({id, offset}, thunkApi) => {
        try {
            const url = offset
            ? `${server}/api/getPodcastById/${id}/${offset}`
            : `${server}/api/getPodcastById/${id}`;
            
            const response = await fetch(url, {
                method:'GET',
                credentials: 'include',
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            thunkApi.dispatch(updatePodcast(results.message))
            return 'success'
        } catch (error) {
            console.log(error)
        }
    }
);

export const getPodcastlistByGenre = createAsyncThunk(
    'auth/getPodcastlistByGenre',
    async ({genre, offset}, thunkApi) => {
        try {

            const url = offset 
            ? `${server}/api/getpodcastlist/${genre}/${offset}`
            : `${server}/api/getpodcastlist/${genre}`;

            const response = await fetch(url, {
                method:'GET',
                credentials: 'include',
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            thunkApi.dispatch(updatePodcastlist(results.message))
            return 'success'
        } catch (error) {
            console.log(error)
        }
    }
);

