import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, Modal, ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import Header from '../../components/Header/header';
import H_Profile from '../../components/Header/h_profile';
import H_Title from '../../components/Header/h_title';
import H_Settings from '../../components/Header/h_settings';
import L_Edit_Playlist from './l_edit_playlist';
import Alli from '../../assets/Alli.png';

import { deletePlaylist, getPlaylistsPreviews } from '../../httpcalls/playlistThunks';
import { getPodcastPreviews, systemLatestPlaylist } from '../../httpcalls/podcastSubscriptionThunk';
import { resetPlaylistPreview } from '../../store/schemas/playlistSlice';

const Library = (props) => {
    const dispatch = useDispatch()
    const playlists = useSelector((state) => state.mainapp.playlistSlice.playlistPreviews)
    const podcasts = useSelector((state) => state.mainapp.podcastSlice.podcastPreviews);
    const [selectedPlaylistId, setSelectedPlaylistId] = useState({playlistId:null, currentTitle:null})
	const [opened, { open , close }] = useDisclosure(false);

    const userPlaylists = playlists.filter((playlist) => playlist.title !== 'Subscription Latest');
    const systemPlaylists = playlists.filter((playlist) => playlist.title === 'Subscription Latest');
    

    useEffect(() => {
            dispatch(getPlaylistsPreviews());
            dispatch(getPodcastPreviews());

        return () => {
			dispatch(resetPlaylistPreview());
			window.scrollTo(0, 0);
		}
	}, [dispatch]);

    const handlePlaylistEdit = (id, currentTitle) => {
        setSelectedPlaylistId({playlistId:id, currentTitle:currentTitle});
        open();
    }

    const renderPlaylist = (playlist, isSystemPlaylist) => {
        const playlistThumbnails = playlist.thumbnails.map((thumbnail, index) => {
            if (index < 2 || playlist.thumbnails.length !== 3) {
                return <img key={thumbnail} src={thumbnail} alt={playlist.title} />;
            }
            return null;
        });

        const playlistTitles = playlist.titles.join(" • ");

        return (
            <div className='library-row' key={playlist.playlist_id}>
                <Link to={String(playlist.playlist_id)} className='library-action'>
                    <div className='library-img-container'>
                        {playlistThumbnails}
                    </div>
                    <div className={isSystemPlaylist ? 'systemPlaylistTextContainer' : 'userPlaylistTextContainer'}>
                        <h1>{playlist.title}</h1>
                        <p>{playlistTitles}</p>
                    </div>
                </Link>
                {!isSystemPlaylist && (
                    <Menu shadow='md' width={150}>
                        <Menu.Target>
                            <button><i className='material-icons'>more_vert</i></button>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item onClick={() => handlePlaylistEdit(playlist.playlist_id, playlist.title)}>
                                <div className='playlist-options'>
                                    <i className='material-icons'>edit</i>
                                    <span>Edit</span>
                                </div>
                            </Menu.Item>
                            <Menu.Item onClick={() => dispatch(deletePlaylist({playlistId: playlist.playlist_id}))}>
                                <div className='playlist-options'>
                                    <i style={{color: 'red' }} className='material-icons'>delete</i>
                                    <span style={{color: 'red' }}>Delete</span>
                                </div>
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                )}
            </div>
        );
    };

    return (
    <>
        <Header topLeft={[<H_Profile signOut={true}/>, <H_Title title={'Library'} />]} topRight={<H_Settings/>} /> 

        <Modal
                opened={opened}
                onClose={close}
                title={"Edit Playlist"}
                scrollAreaComponent={ScrollArea.Autosize}
                padding={'lg'}
                size={'sm'}
                className='podcast-modal'
            >
                {<L_Edit_Playlist currentTitle={selectedPlaylistId.currentTitle} playlistId={selectedPlaylistId.playlistId} close={close}/>}
        </Modal>

        <div className='library-container'>
            {/* - System Playlist Section - */}
            <div className='systemPlaylistHeader'>
                <h2 className='systemPlaylistTitle'>System Playlists:</h2>
                
            </div>
            <div className='systemPlaylistContainer'>
                {systemPlaylists.length === 0 ? (
                    <div className='systemPlaylistHeader'>
                        <h3 className="systemPlaylistTitle">Doesn't look like you have any new episodes yet!</h3>
                        <img src={Alli} alt='Alli' />
                    </div>
                ) : (
                    systemPlaylists.map(playlist => renderPlaylist(playlist, true))
                )}
            </div>

            {/* - User Playlist Section - */}
            <h2 className='userPlaylistTitle'>User Playlists:</h2>
            <div className='userPlaylistContainer'>
                {userPlaylists.map(playlist => renderPlaylist(playlist, false))}
            </div>

            {/* - Subscriptions Section - */}
            <h2 className='subscriptionTitle'>Subscriptions:</h2>
            <div className='discover-overflow'>
                    {podcasts.length === 0 ? <h1 className='subscription-empty'>No Subscriptions Found...</h1> :  
                        podcasts.map((podcast) => {
                            return (
                                <div className='library-row' key={podcast.id}>
                                    <Link to={'/discover/podcast/' + podcast.podcastinfo.listennotes_id} className='discover-overflow-item'>
                                            <img src={podcast.podcastinfo.thumbnail} alt='' />
                                            <h5 style={{color: '#D84465'}}>{podcast.title}</h5>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
        </div>
    </>
    );
};

export default Library;
