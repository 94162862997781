import React, { useState } from 'react';
import { searchPodcasts } from '../../httpcalls/searchThunk';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header/header';
import HProfile from '../../components/Header/h_profile';
import HTitle from '../../components/Header/h_title';
import HSettings from '../../components/Header/h_settings';
import SearchBox from './s_searchbox';


const PodcastsSearch = () => {
  const dispatch = useDispatch();
	const searchQuery = useSelector((state) => state.mainapp.searchSlice.searchQuery);
  const searchResults = searchQuery.results;
  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');



  const loadMoreResults = () => {
    const newOffset = offset + 10;
    setOffset(newOffset);
    dispatch(searchPodcasts({ search: searchTerm, offset: newOffset}));
  };

  return (
    <>
      <Header topLeft={[<HProfile signOut={true}/>, <HTitle title={'Search'} />]} topRight={<HSettings />} />
      <div className='search_container'>
        {searchResults && searchResults.length === 0 && <h1>Search Podcasts</h1>}
            <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            {searchResults && searchResults.length > 0 && (
              <>
                <p>{searchResults.genre}</p>
                <div className='podcastlist-grid-container'>
                  {searchResults.map((podcast, index) => (
                    <div className='podcastlist-grid-item' key={index}>
                      <Link to={`/discover/podcast/${podcast.id}`}>
                        <img src={podcast.thumbnail} alt={podcast.title_original} />
                      </Link>
                      <div className='podcastlist-header-text'>
                        <h5>{podcast.title_original}</h5>
                        <p>{podcast.publisher_original}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <>
                  {searchTerm && searchTerm.length > 0 ? (
                    <div className='load_more'>
                      <button onClick={loadMoreResults}>Load More</button>
                    </div>
                  ) : null}
                </>
              </>
            )}
      </div>
    </>
  );
};

export default PodcastsSearch;