import React, { useEffect, useState } from 'react';

const InstallPWA = ({ installPromptEvent }) => {


  const showInstallPrompt = () => {
    if (installPromptEvent) {
      installPromptEvent.prompt(); // Show the installation prompt
      // Handle user's response to the prompt
      installPromptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
      });
    }
  };

  return (
    <div>
      {installPromptEvent && (
        <div onClick={showInstallPrompt}>
          <h5>Install Alli App</h5>
        </div>
      )}
    </div>
  );
};

export default InstallPWA;
