import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    playlistTitle: null,
    episodes:[],
    podcasts:[],
    playlistPreviews:[]
      
})


const playlistSlice = createSlice({
    name: 'playlistSlice',
    initialState: initialState(),
    reducers: {
        resetPlaylistSlice: state => initialState(),
        resetPlaylistSpecific: (state, action) => {
            state.playlistTitle = null
            state.episodes = []
        },
        resetPlaylistPreview: (state, action) => {
            state.playlistPreviews = []
        },
        updatePlaylist: (state, action) => {
            state.playlistTitle = action.payload.playlist_title
            state.episodes = action.payload.episodes
            state.podcasts = action.payload.podcasts
        },
        updatePlaylistPreviews: (state, action) => {
            state.playlistPreviews = action.payload
        },
        updateEpisodesOrder: (state, action) => {
            state.episodes = action.payload.newOrder;
        },
    }
})

export const {resetPlaylistSpecific, resetPlaylistSlice, resetPlaylistPreview, updatePlaylist, updatePlaylistPreviews, updateEpisodesOrder } = playlistSlice.actions

export default playlistSlice.reducer