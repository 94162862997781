import React from 'react';
import { Timeline } from '@mantine/core';

import Alli from '../../assets/Alli.png';
import Header from '../../components/Header/header';
import HBack from '../../components/Header/h_back';
import HTitle from '../../components/Header/h_title';
import HSettings from '../../components/Header/h_settings';


const PostOne = () => {
    return (
        <div className='milestoneContainer'>
            <div className='postSubContainer'>
                <img className='postAlli' src={Alli} alt='Alli' />
                <div className='postText'>
                    <h1 className='postUser'>Alli.Media</h1>
                    <p>@AlliDotMedia</p>
                </div>
            </div>
            <p className='flavorText'>
                Alli’s Twitter/X Account is live! Know what else is live? The alpha version of our podcast player web app! Check it out at https://Alli.Media and tell us what you think. But be nice - we are just getting started!
            </p>
        </div>
    );
};

const PostTwo = () => {
    return (
        <div className='milestoneContainer'>
            <div className='postSubContainer'>
                <img className='postAlli' src={Alli} alt='Alli' />
                <div className='postText'>
                    <h1 className='postUser'>Alli.Media</h1>
                    <p>@AlliDotMedia</p>
                </div>
            </div>
            <p className='flavorText'>
                Hey Twitterverse! The alpha version of our podcast player web app went live earlier this month! Check us out at https://Alli.Media and let us know what you think!
            </p>
        </div>
    );
};

const PostThree = () => {
    return (
        <div className='milestoneContainer'>
            <div className='postSubContainer'>
                <img className='postAlli' src={Alli} alt='Alli' />
                <div className='postText'>
                    <h1 className='postUser'>Alli.Media</h1>
                    <p>@AlliDotMedia</p>
                </div>
            </div>
            <div>
                <p className='flavorText'>
                    Probably not going to meet the 02/01/24 goal of getting our Android App released!  Still working out some final issues and don't want to release prematurely.
                </p>
            </div>
        </div>
    );
};

const PostFour = () => {
    return (
        <div className='milestoneContainer'>
            <div className='postSubContainer'>
                <img className='postAlli' src={Alli} alt='Alli' />
                <div className='postText'>
                    <h1 className='postUser'>Alli.Media</h1>
                    <p>@AlliDotMedia</p>
                </div>
            </div>
            <div>
                <p className='flavorText'>
                    We may not have the Android App released yet but the Web App at https://Alli.Media is good to go!
                </p>
            </div>
        </div>
    );
};

const PostFive = () => {
    return (
        <div className='milestoneContainer'>
            <div className='postSubContainer'>
                <img className='postAlli' src={Alli} alt='Alli' />
                <div className='postText'>
                    <h1 className='postUser'>Alli.Media</h1>
                    <p>@AlliDotMedia</p>
                </div>
                
            </div>
            <div>
                <p className='flavorText'>
                Hey Folks!  Our first System Playlist has been released!  Called "Subscription Latest", this System Playlist listens for the latest episode from each of your subscribed podcasts and automatically adds them to this playlist for you!
                </p>
            </div>
        </div>
    );
};

const SocialMedia = () => {

    return (
        <>
            <Header topLeft={[<HBack />, <HTitle title={"Social"} />]} topRight={<HSettings/>}  />
            <div className='roadmapContainer'>    
                <h1 className='socialHeader'> Follow us <a href='https://twitter.com/AlliDotMedia'>AlliDotMedia</a>!</h1>
                <div className='timelineContainer'>
                    <Timeline active={5} bulletSize={24} lineWidth={3} >
                        <Timeline.Item title='Feb 2, 2024'>
                            <PostFive />
                        </Timeline.Item>

                        <Timeline.Item title='Jan 27, 2024'>
                            <PostFour />
                        </Timeline.Item>

                        <Timeline.Item title='Jan 27, 2024'>
                            <PostThree />
                        </Timeline.Item>

                        <Timeline.Item title='Jan 16, 2024'>
                            <PostTwo />
                        </Timeline.Item>

                        <Timeline.Item title='Jan 15, 2024'>
                            <PostOne />
                        </Timeline.Item>

                    </Timeline>
                </div>
            </div>
        </>
    );
};

export default SocialMedia;