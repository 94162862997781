import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import google from '../../assets/google.png';
import Alli from '../../assets/alli_purple.png';
import HTitle from '../../components/Header/h_title';
import {ReactComponent as Background} from '../../assets/background.svg';
import Header from '../../components/Header/header';

function Login() {
    const [installPromptEvent, setInstallPromptEvent] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
        e.preventDefault(); // Prevent automatic prompt display
        setInstallPromptEvent(e); // Save the event for later use
        console.log('PWA installation prompt is ready.');
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const googleAuth = () => {
        window.open (
            `${process.env.REACT_APP_API_URL}/auth/google`,
            "_self"
        );
    };

    return (
        <>
        <Header homepage={true} installPrompt={installPromptEvent} topRight={[<HTitle title={'Alli Media'}/>]}/>
        <div className="login-container">
            <div className='login-background'>
                <Background />
            </div>

            <div className='login-head-content'>
                <h3>Your own personal media aggre<span>gator</span>.</h3>
            </div>

            <div className='login-middle-content'>
                <img src={Alli} alt="Alli Home"/>
                <h2>Alli helps you find, play, and organize the content you care about.</h2>
                <h3>Alli is currently in Alpha release, and is not ready for public use. Thank you for your paitence while we strive to get Alli ready for you. </h3>
            </div>

            <div className="login-form-container">
            <p>By signing up, you agree to the <Link to='/termsofservice'>Terms of Service</Link> and <Link to='/privacypolicy'>Privacy Policy</Link>, including Cookie Use.</p>
                <button onClick={googleAuth}>
                    <img src={google} alt="google icon" />
                    <span>Sign in with Google</span>
                </button>
            </div>
        </div>
        </>
    );
}

export default Login;