import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateAudioPause, updateSleepTimerDuration, updateIsSleepTimerActive } from '../../store/schemas/audioSlice';

const SleepTimer = () => {
    const dispatch = useDispatch();

    const audioPlayback = useSelector((state) => state.mainapp.audioSlice.audioPlayback);
    const audioPlaybackEnd = useSelector((state) => state.mainapp.audioSlice.audioPlaybackEnd);
    const sleepTimerDuration = useSelector((state) => state.mainapp.audioSlice.sleepTimerDuration);
    const isSleepTimerActive = useSelector((state) => state.mainapp.audioSlice.isSleepTimerActive);

    const [selectedTimerOption, setSelectedTimerOption] = useState("turn-off-timer");

    useEffect(() => {
        let timerId;

        if (isSleepTimerActive && sleepTimerDuration) {
            timerId = setTimeout(() => {
                // Pause or stop playback
                dispatch(updateAudioPause(true));
                dispatch(updateIsSleepTimerActive(false));
            }, sleepTimerDuration);
        }
        return () => clearTimeout(timerId);
    }, [isSleepTimerActive, sleepTimerDuration, dispatch]);

    const handleSetSleepTimer = (duration) => {
        setSelectedTimerOption(duration);
        if (duration === "turn-off-timer") {
            dispatch(updateSleepTimerDuration(0));
            dispatch(updateIsSleepTimerActive(false));

        } else if (duration === "end-of-episode") {
            const durationToEnd = (audioPlaybackEnd - audioPlayback -1) * 1000;
            dispatch(updateSleepTimerDuration(durationToEnd));
            dispatch(updateIsSleepTimerActive(true));

        } else {
            dispatch(updateSleepTimerDuration(duration));
            dispatch(updateIsSleepTimerActive(true));
        }
    };

    return (
        <div className='sleep-timer'>
            { !isSleepTimerActive ? (
                <label for="sleepTimer"><i className='material-icons'>bedtime_off</i></label>
            ) : (
                <label for="sleepTimer"><i style={{color: 'green'}} className='material-icons'>bedtime_off</i></label>
            )}
                <select id='sleepTimer' onChange={(e) => handleSetSleepTimer(e.target.value)} value={selectedTimerOption}>
                    <option value={"turn-off-timer"}>Turn off Timer</option>
                    <option value={5 * 60 * 1000}>5 min</option>
                    <option value={10 * 60 * 1000}>10 min</option>
                    <option value={15 * 60 * 1000}>15 min</option>
                    <option value={30 * 60 * 1000}>30 min</option>
                    <option value={45 * 60 * 1000}>45 min</option>
                    <option value={60 * 60 * 1000}>1 hr</option>
                    <option value={"end-of-episode"}>End of Episode</option>
                </select>
            
        </div>
    );
};

export default SleepTimer;