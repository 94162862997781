import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { searchPodcasts } from '../../httpcalls/searchThunk';
import { resetSearchSlice } from '../../store/schemas/searchSlice';

const SearchBox = memo(({searchTerm, setSearchTerm}) => {
    const dispatch = useDispatch()

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchButtonClick = () => {
      window.scrollTo(0,0);
      if (searchTerm.trim() !== '') {
        dispatch(resetSearchSlice());
        dispatch(searchPodcasts({ search: searchTerm }));
      } else {
        dispatch(resetSearchSlice());
      }
    };

    return (
        <div className='search_box'>
            <input
            type="text"
            value={searchTerm}
            onChange={handleSearchInputChange}
            placeholder="Enter search term"
            />
            <button onClick={handleSearchButtonClick}>Search</button>
        </div>
    );
});

export default SearchBox;
