import { createAsyncThunk }  from '@reduxjs/toolkit';
import { updateHistory } from '../store/schemas/historySlice';

const server = process.env.REACT_APP_API_URL

export const createHistory = createAsyncThunk (
    'history/addHistory',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/history/addHistory`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data), 
            });

            const results = await response.json();
            if (!response.ok || "error" in results) {
                return thunkApi.rejectWithValue(results);
            } 
            return results;
        } catch (error) {
            console.error('Fetch error:', error);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const updateEpisodeTime = createAsyncThunk (
    'history/updateEpisodeTime',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/history/updateEpisodeTime`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify(data),
            });

            const results = await response.json();
            if (!response.ok || 'error' in results) {
                return thunkApi.rejectWithValue(results);
            }
            return results;
        } catch (error) {
            console.error('Fetch error:', error);
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const getEpisodeHistory = createAsyncThunk(
    'history/getEpisodeHistory',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/history/getEpisodeHistory`, {
                method:'GET',
                credentials: 'include'
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }

            thunkApi.dispatch(updateHistory(results))
            return 'success'
        
        } catch (error) {
            console.log(error)
        }
    }
);