import { createAsyncThunk } from '@reduxjs/toolkit'
import {resetAuth, userInformation } from '../store/schemas/authSlice'
// import { notifications } from '@mantine/notifications';

const server = process.env.REACT_APP_API_URL

export const getUser = createAsyncThunk(
    'auth/getUser',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/auth/login/success`, {
                method:'GET',
                credentials: 'include',
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            thunkApi.dispatch(userInformation(results.message.user))
            return 'success'
        
        } catch (error) {
            console.log(error)
        }
    }
  )

  export const logout = createAsyncThunk(
    'auth/logout',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/auth/logout`, {
                method:'GET',
                credentials: 'include'
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            localStorage.clear()
            window.location.href = '/'
        } catch (error) {
            localStorage.clear()
            thunkApi.dispatch(resetAuth())
        }
    }
  )
