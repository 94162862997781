import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    userInfo: null
})


const authSlice = createSlice({
    name: 'user_auth',
    initialState: initialState(),
    reducers: {
        resetAuth: state => initialState(),
        userInformation: (state, action) => {
            state.userInfo = action.payload 
        }
        
    }
})

export const { resetAuth, userInformation } = authSlice.actions

export default authSlice.reducer