import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateSearchQuery } from "../store/schemas/searchSlice";

const server = process.env.REACT_APP_API_URL

export const searchPodcasts = createAsyncThunk(
    'search/searchPodcasts',
    async ({search, offset}, thunkApi) => {
        try {
            const url = offset
            ? `${server}/api/searchpodcasts/${search}/${offset}`
            : `${server}/api/searchpodcasts/${search}`;

            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
            })
            const results = await response.json()
            if("error" in results) {
                throw results
            }
            thunkApi.dispatch(updateSearchQuery(results.message))

            return 'success'
        } catch (error) {
            console.log(error)
        }
    }
);