import React from "react";
import { Menu } from "@mantine/core";
import { Link } from "react-router-dom";

const H_Settings = () => {

	return (

		<>
		<Menu shadow="md" width={200} >
			<Menu.Target>
					<i className="material-icons">settings</i>
			</Menu.Target>

			<Menu.Dropdown>
				<Menu.Item onClick={() => {}}>
					<div className="profile-logout">
						<Link to={"/contact"} >
                            <h5>Contact Us</h5>
                        </Link>
					</div>
				</Menu.Item>
				<Menu.Item onClick={() => {}}>
					<div className="profile-logout">
						<Link to={"/about"} >
                            <h5>About Us</h5>
                        </Link>
					</div>
				</Menu.Item>
				<Menu.Item >
					<div className="profile=logout">
						<Link to={"/timeline"} >
							<h5>Roadmap</h5>
						</Link>
					</div>
				</Menu.Item>
				<Menu.Item >
					<div className="profile=logout">
						<Link to={"/social"} >
							<h5>Social</h5>
						</Link>
					</div>
				</Menu.Item>


			</Menu.Dropdown>

		</Menu>
		</>
    )
}

export default H_Settings;