import { createAsyncThunk } from '@reduxjs/toolkit'
import { updatePlaylist, updatePlaylistPreviews } from '../store/schemas/playlistSlice'


const server = process.env.REACT_APP_API_URL

export const createPlaylistWithPodcast = createAsyncThunk(
    'playlists/createPlaylistWithPodcast',
    async (data, thunkApi) => {
        try {
            let podcastInfoFormatted = {...data.podcastInfo}
            delete podcastInfoFormatted.episodes
            const response = await fetch(`${server}/playlist/createPlaylistWithPodcast`, {
                method:'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'  
                },
                body: JSON.stringify({playlistTitle:data.playlistTitle, episode:data.episode, podcastInfo:podcastInfoFormatted})
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            return 'success'
        
        } catch (error) {
            console.log(error)
        }
    }
);


export const getPlaylist = createAsyncThunk(
    'playlists/getPlaylist',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/playlist/getPlaylist/${data.playlistId}`, {
                method:'GET',
                credentials: 'include'
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }

            thunkApi.dispatch(updatePlaylist(results.message))
            return 'success'
        
        } catch (error) {
            console.log(error)
        }
    }
);

export const getPlaylistsPreviews = createAsyncThunk(
    'playlists/getPlaylistsPreviews',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/playlist/getPlaylistsPreviews`, {
                method:'GET',
                credentials: 'include',
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }

            thunkApi.dispatch(updatePlaylistPreviews(results.message))
            return 'success'
        
        } catch (error) {
            console.log(error)
        }
    }
);

export const addToPlaylist = createAsyncThunk(
    'playlists/addToPlaylist',
    async (data, thunkApi) => {
        try {
            let podcastInfoFormatted = {...data.podcastInfo}
            delete podcastInfoFormatted.episodes
            const response = await fetch(`${server}/playlist/addToPlaylist`, {
                method:'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'  
                },
                body: JSON.stringify({playlistId:data.playlistId, episode:data.episode, podcastInfo:podcastInfoFormatted})
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            return 'success'
        
        } catch (error) {
            console.log(error)
        }
    }
);

export const removePlaylistEpisode = createAsyncThunk(
    'playlists/removePlaylistEpisode',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/playlist/removePlaylistEpisode`, {
                method:'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'  
                },
                body: JSON.stringify({playlistId:data.playlistId, episodeDbId:data.episodeDbId})
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            await thunkApi.dispatch(getPlaylist({playlistId:data.playlistId}))
            return 'success'
        
        } catch (error) {
            console.log(error)
        }
    }
);

export const deletePlaylist = createAsyncThunk(
'playlists/deletePlaylist',
async (data, thunkApi) => {
    try {
        const response = await fetch(`${server}/playlist/deletePlaylist`, {
            method:'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'  
            },
            body: JSON.stringify({playlistId:data.playlistId})
        })
        const results = await response.json()
        if("error" in results){
            throw results
        }
        await thunkApi.dispatch(getPlaylistsPreviews())
        return 'success'
    
    } catch (error) {
        console.log(error)
    }
}
);

export const editPlaylistInfo = createAsyncThunk(
    'playlists/editPlaylistInfo',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/playlist/editPlaylistInfo`, {
                method:'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'  
                },
                body: JSON.stringify({playlistId:data.playlistId, playlistTitle:data.playlistTitle})
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            await thunkApi.dispatch(getPlaylistsPreviews())
            return 'success'
        
        } catch (error) {
            console.log(error)
        }
    }
);

export const updateEpisodesOrderInBackend = createAsyncThunk (
    'playlists/updateEpisodesOrderInBackend',
    async ({ playlistId, episodes }, thunkApi) => {
        try {
            console.log('thunk:',episodes);
            const response = await fetch(`${server}/playlist/updateEpisodeOrder`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ playlistId, episodes })
            });
            const results = await response.json();
            if ('error' in results) {
                throw results;
            }
            return 'success';
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
);