import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/header";
import HProfile from "../../components/Header/h_profile";
import HTitle from "../../components/Header/h_title";
import HSettings from "../../components/Header/h_settings";
import { discoverPodcasts } from "../../httpcalls/podcastThunk";
import { Link } from "react-router-dom";
import { Loader } from "@mantine/core";
import { systemPlaylist } from "../../httpcalls/podcastSubscriptionThunk";


const Discovery = () => {
    const rows = useSelector((state) => state.mainapp.discoverSlice.recommendedRows)
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(discoverPodcasts())
            .finally(() => setIsLoading(false));
        window.scrollTo(0, 0)
    },[])

	return (
		<>
            <Header topLeft={[<HProfile signOut={true} />, <HTitle title={"Popular Podcasts"} />]} topRight={[<HSettings />]} />
            {isLoading ?
                <div className='loader-container'>
                    <Loader />
                </div> :
            rows.length !== 0 &&
                rows.map((row, index) => {
                    return (
                            <div className="discover-container" key={index}>
                                <div className="discover-title">
                                    <h2>{row.title}</h2>
                                    <Link to={'/discover/podcastlist/' + row.title} >
                                        <p>Discover More Like This...</p>
                                    </Link>
                                </div>
                                <div className="discover-overflow">
                                    {row.content.map((podcast) => {
                                        return (
                                            <Link to={'/discover/podcast/' + podcast.id} className="discover-overflow-item">
                                                <img src={podcast.thumbnail} alt={podcast.title} />
                                                <h5>{podcast.title}</h5>
                                                <p>{podcast.publisher}</p>
                                            </Link>
                                        )
                                    })}
                                </div>
                            </div>
                    )
                })
            }
		</>
	);
}

export default Discovery;