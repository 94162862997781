import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    activeAudioIndex:null,
    audioList:[],
    audioPlayback:0,
    audioPlaybackEnd:0,
    pause:false,
    audioPlaybackSpeed:1,
    sleepTimerDuration:600000,
    isSleepTimerActive:false,
    playAgain:false,
})


const audioSlice = createSlice({
    name: 'audioSlice',
    initialState: initialState(),
    reducers: {
        resetAudioSlice: state => initialState(),
        addAudio: (state, action) => {
            if(state.audioList.length == 0){
                // Clicked podcast by user
                state.audioList = action.payload.audioList
                state.activeAudioIndex = action.payload.audioIndex
            }
            else{
                // Propagated by server
                if(state.audioList.length >= 30){
                    let audioListSize = action.payload.audioList.length
                    // Remove the 30 - audioListSize elements based on direction clean up
                }

                if(action.payload.direction == 'next'){
                    state.audioList.push(...action.payload.audioList)
                }
                else{
                    state.audioList.unshift(...action.payload.audioList)
                }
            }
        },
        overwriteAudio: (state, action) => {
            state.audioList = action.payload.audioList
            state.activeAudioIndex = action.payload.activeAudioIndex
            state.audioPlayback = 0
            state.audioPlaybackEnd = 0
        },
        overwriteAudioIndex: (state, action) => {
            state.activeAudioIndex = action.payload
            state.audioPlayback = 0
            state.audioPlaybackEnd = 0
        },
        updateEpisodePlayback: (state, action) => {
            const { id, playbackTime } = action.payload;
            const episodeIndex = state.audioList.findIndex(episode => episode.id === id);
            if (episodeIndex !== -1) {
                state.audioList[episodeIndex].playback_time = playbackTime;
            }
        },
        updateAudioPlayback: (state, action) => {
            state.audioPlayback = action.payload
        },
        updateAudioPlaybackEnd: (state, action) => {
            state.audioPlaybackEnd = action.payload
        },
        updateAudioPause: (state, action) => {
            state.pause = action.payload
        },
        updateAudioPlaybackSpeed: (state, action) => {
            state.audioPlaybackSpeed = action.payload
        },
        updatePlaylistOrder: (state, action) => {
            state.audioList = action.payload.newOrder;
        },
        updateSleepTimerDuration: (state, action) => {
            state.sleepTimerDuration = action.payload
        },
        updateIsSleepTimerActive : (state, action) => {
            state.isSleepTimerActive = action.payload
        },
        updatePlayAgain : (state, action) => {
            state.playAgain = action.payload
        },
    }
})

export const { resetAudioSlice, addAudio, overwriteAudio, overwriteAudioIndex, updateEpisodePlayback, updateAudioPlayback, updateAudioPause, updateAudioPlaybackEnd, updateAudioPlaybackSpeed, updatePlaylistOrder, updateSleepTimerDuration, updateIsSleepTimerActive, updatePlayAgain } = audioSlice.actions

export default audioSlice.reducer