import React from 'react';
import { Menu } from '@mantine/core';
import { Link } from 'react-router-dom';
import InstallPWA from '../Helper/installPWA';

const headerMenu = ({ installPrompt }) => {

    return (
        <>
            <Menu shadow='md' width={200} >
                <Menu.Target>
                    <i className='material-icons'>menu</i>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item>
                        <div className='homepage_menu'>
                            <Link to={'/about'} >
                                <h5>About Us</h5>
                            </Link>
                        </div>
                    </Menu.Item>
                    <Menu.Item>
                        <div className='homepage_menu'>
                            <Link to={'/contact'} >
                                <h5>Contact Us</h5>
                            </Link>
                        </div>
                    </Menu.Item>
                    <Menu.Item>
                        <div className='homepage_menu'>
                            <Link to={'/social'} >
                                <h5>Social</h5>
                            </Link>
                        </div>
                    </Menu.Item>
                    <Menu.Item>
                        <div className='homepage_menu'>
                            <InstallPWA installPromptEvent={installPrompt}/>
                        </div>
				    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </>
    );
};

export default headerMenu;