import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Loader } from "@mantine/core";
import { notifications } from '@mantine/notifications';

import Header from '../../components/Header/header';
import { useAudio } from '../../components/Audio/howler';
import HBack from '../../components/Header/h_back';
import HTitle from '../../components/Header/h_title';
import audioWave from '../../assets/audio-wave.gif';
import PDescription from '../Podcasts/p_description';

import { addAudio, resetAudioSlice, updatePlaylistOrder, updatePlayAgain } from '../../store/schemas/audioSlice';
import { resetPlaylistSpecific, updateEpisodesOrder } from '../../store/schemas/playlistSlice';
import { getPlaylist, removePlaylistEpisode, updateEpisodesOrderInBackend } from '../../httpcalls/playlistThunks';
import { systemLatestPlaylist } from '../../httpcalls/podcastSubscriptionThunk';


const L_Playlist = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();	
	const { playlistId } =  useParams();
	const { unload } = useAudio();

	const podcasts = useSelector((state) => state.mainapp.playlistSlice.podcasts);
	const playlistTitle = useSelector((state) => state.mainapp.playlistSlice.playlistTitle);
	const episodes = useSelector((state) => state.mainapp.playlistSlice.episodes);
	const audioIndex = useSelector((state) => state.mainapp.audioSlice.activeAudioIndex);
    const audioItem = useSelector((state) => state.mainapp.audioSlice.audioList[audioIndex]);
	const currentPlayingId	= audioItem?.id;
	const [isLatest, setIsLatest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		dispatch(getPlaylist({playlistId:playlistId}));

		return (() => {
			dispatch(resetPlaylistSpecific());
			window.scrollTo(0, 0);
		})
	}, [dispatch, playlistId])
	
	const updateSystemPlaylist = async () => {
        setIsLoading(true);
		if (playlistTitle === "Subscription Latest") {unload(); dispatch(resetAudioSlice())};
        try {
            //Dispatch thunk and wait for it to complete
            await dispatch(systemLatestPlaylist()).unwrap();
			dispatch(getPlaylist({playlistId:playlistId}));
            setIsLatest(true);
        } finally {
            //Reset loading state irrespective of outcome
            setIsLoading(false); 
        }
    };

	//Format Publish Date into yyyy-mm-dd
	const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const day = date.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    };

	//Format for audio_length_sec 
	const formatDuration = (time) => {
		if (time >= 60) {
			const minutes = Math.floor(time / 60);
			return `${minutes} min`;
		} else {
			return `${time} sec`;
		}
	};

	//Format for Partially listened episodes 
	const formatPartialDuration = (totalLength, playback) => {
		const time = totalLength - playback;

		if (time >= 60) {
			const minutes = Math.floor(time / 60);
			return `${minutes} mins left`;
		} else {
			return `${time} secs left`;
		}
	};

	const audioWaveButton = () => {
		<div>
			{audioWave}
		</div>
	};

	const handleIsPlayingClick = () => {
		navigate('/player');
	};

	//Load audio and navigate to the player
	const startPlayer = (index) => {
		if (!isLoading) {
			dispatch(resetAudioSlice());
			dispatch(addAudio({audioList:episodes, audioIndex: index}));
			navigate('/player');
		} else {
			notifications.show({
				title: 'Update in progress',
				message: 'Please wait to the Playlist to finish updating.',
				color: 'blue',
				autoClose: 5000,
				position: 'top-right',
			});
		}
	};

	const playAgain = async (id, index) => {
		if (!isLoading) {
			try {
				dispatch(resetAudioSlice());
				dispatch(addAudio({audioList:episodes, audioIndex: index}));
			} catch (error) {
				console.error("Failed to reset playback time", error);
			} finally {
				dispatch(updatePlayAgain(true));
				navigate('/player');
			}
		} else {
			notifications.show({
				title: 'Update in progress',
				message: 'Please wait to the Playlist to finish updating.',
				color: 'blue',
				autoClose: 5000,
				position: 'top-right',
			});
		}
	};

	//Remove Episode from playlist
	const removeSelectedItem = (episodeDbId) => {
		dispatch(removePlaylistEpisode({playlistId:playlistId, episodeDbId:episodeDbId}))
	};

	//Handle the drag operation
	const onDragEnd = (result) => {
		const {destination, source } = result;

		if (!destination || destination.index === source.index) {
			return;
		}

		//Creating new episodes array with the new order
		const newEpisodes = Array.from(episodes);
		const [reordered] = newEpisodes.splice(source.index, 1);
		newEpisodes.splice(destination.index, 0, reordered);

		//Constructing payload for backend with new order index in arrary
		const updatePayload = newEpisodes.map((episode, index) => ({
			db_id: episode.db_id,
			id: episode.id,
			title: episode.title,
			order: index,
		}));
		console.log("UpdatedPayload:",updatePayload);

		dispatch(updatePlaylistOrder({ newOrder: newEpisodes }));
		dispatch(updateEpisodesOrder({ newOrder: newEpisodes }));
		dispatch(updateEpisodesOrderInBackend({ playlistId, episodes: updatePayload }));
	};

	return playlistTitle ? (
		<>
			<Header topLeft={[<HBack />, <HTitle title={playlistTitle}/>]} />

            {/*div className="discover-container">
				<h2>Podcasts</h2>
				<div className="discover-overflow">
					{podcasts.map((podcast) => {
						return (
							<>
								<Link to={'/discover/podcast/' + podcast.id} className="discover-overflow-item">
									<img src={podcast.thumbnail}/>
									<h5>{podcast.title}</h5>
									<p>{podcast.publisher}</p>
								</Link>
							</>
						)
					})}
						
				</div>
			</div>*/}
			<div className="systemPlaylistLoader">
				{isLoading ? (
                    <div className='loadingSystemPlaylist'>
                            <Loader type='dots'/>
                        </div>
                    
                ) : isLatest ? (
                        <div className='latestSystemPlaylist'>
                            <p>Updated</p>
                        </div>
                ) : (
                        <button className='updateSystemPlaylist' onClick={updateSystemPlaylist}>Update Playlist!</button>
                )}
			</div>
			
			<div className='podcast-container'>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId='droppable-playlist'>
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{episodes.map((episode, index) => {
										const dateObject = new Date(episode.pub_date_ms);
										const formattedDate = formatDate(dateObject);		
										const formattedDuration = formatDuration(episode.audio_length_sec);	
										const formattedPartialDuration = formatPartialDuration(episode.audio_length_sec, episode.playback_time);	
										const isListenedTo = episode.playback_time >= (episode.audio_length_sec *.9);
										const isUnfinished = episode.playback_time > 0 && episode.playback_time <= (episode.audio_length_sec * 0.9);

										return (
											<Draggable key={episode.id} draggableId={episode.id.toString()} index={index}>
												{(provided) => (
													<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='podcast-episode'>
														<Link to={'/discover/podcast/' + episode.podcast_id}>
															<div className='podcast-episode-header'>
																<img src={episode.thumbnail} alt={episode.title} />
																<div className='podcast-episode-header-text'>
																	<h3>{episode.podcast_title}</h3>
																	<h5>{episode.title}</h5>
																	{formattedDate && (<p>Published: {formattedDate} </p>)}
																</div>
															</div>
														</Link>
														<PDescription html={episode.description} title={episode.title} />

														<div className='podcast-episode-buttons'>
															{currentPlayingId === episode.id ? (
																<img onClick={handleIsPlayingClick} src={audioWave} alt={episode.title}/>
															) : isListenedTo ? (
																<button onClick={(e) => {playAgain(episode.id, index)}}>
																	<i className="material-symbols-outlined">restart_alt</i>
																	<p>Play Again</p>
																</button>
															) : isUnfinished ? (
																<button onClick={(e) => {startPlayer(index)}}>
																	<i className="material-symbols-outlined">play_circle</i>
																	{formattedPartialDuration}
																</button>
															) : (
																<button onClick={(e) => {startPlayer(index)}}>
																	<i className="material-symbols-outlined">play_circle</i>
																	{formattedDuration}
																</button>
															)}
															<button onClick={(e) => {removeSelectedItem(episode.db_id)}}><i className="material-icons">playlist_remove</i>Remove</button>
														</div>
													</div>
												)}
											</Draggable>
										);
								})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
			</div>
		</>
		) : null
};


export default L_Playlist;
