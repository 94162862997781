import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    recommendedRows: [],
    genres: [
        {"genre": "Arts", "icon": "brush"},
        {"genre": "Business", "icon": "business_center"},
        {"genre": "Comedy", "icon": "theater_comedy"},
        {"genre": "Education", "icon": "school"},
        {"genre": "Fiction", "icon": "auto_stories"},
        {"genre": "Government", "icon": "account_balance"},
        {"genre": "Health & Fitness", "icon": "health_and_safety"},
        {"genre": "History", "icon": "history_edu"},
        {"genre": "Kids & Family", "icon": "family_restroom"},
        {"genre": "Leisure", "icon": "weekend"},
        {"genre": "Locally Focused", "icon": "storefront"},
        {"genre": "Music", "icon": "music_note"},
        {"genre": "News", "icon": "newspaper"},
        {"genre": "Personal Finance", "icon": "payments"},
        {"genre": "Religion & Spirituality", "icon": "church"},
        {"genre": "Science", "icon": "biotech"},
        {"genre": "Society & Culture", "icon": "diversity_2"},
        {"genre": "Sports", "icon": "sports_handball"},
        {"genre": "Technology", "icon": "devices"},
        {"genre": "True Crime", "icon": "gavel"},
        {"genre": "TV & Film", "icon": "desktop_windows"}
    ]
      
})


const discoverSlice = createSlice({
    name: 'disoverSlice',
    initialState: initialState(),
    reducers: {
        resetDiscoverSlice: state => initialState(),
        updateRecommendedRows: (state, action) => {
            state.recommendedRows = action.payload
        },
    }
})

export const { resetDiscoverSlice, updateRecommendedRows } = discoverSlice.actions

export default discoverSlice.reducer