import React, { useEffect, useMemo } from 'react';
import DOMPurify from 'dompurify';
import { Modal, ScrollArea  } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import clip from "text-clipper";

const P_Description = ({html, title, clipLimit=140}) => {
    const [opened, { open, close }] = useDisclosure(false);

	const purifyHtml = (html) => {
        const alteredHtm =  html
		const safeHTML = DOMPurify.sanitize(alteredHtm);
		return safeHTML
	}
      
      

    const pureHtml = useMemo(() => purifyHtml(html), [html]);

	return (


		<>
        <Modal
            opened={opened}
            onClose={close}
            title={title}
            scrollAreaComponent={ScrollArea.Autosize}
            padding={'lg'}
            size={'sm'}
            className='podcast-modal'
        >
            {<p  className='podcast-overview' dangerouslySetInnerHTML={{__html:pureHtml}}></p>}
        </Modal>
      
            <p onClick={open}  className='podcast-preview' dangerouslySetInnerHTML={{__html:( clip(pureHtml, clipLimit, {html:true, stripTags:[ 'strong']}) + ' <span>View More</span>' )}}></p>
           
        
		</>
	);
};


export default P_Description;
