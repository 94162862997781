import React, { useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/Header/header';
import HBack from '../../components/Header/h_back';
import { useAudio } from '../../components/Audio/howler';
import PlaybackSpeed from './playbackSpeed';
import PDescription from '../Podcasts/p_description';
import SleepTimer from './sleepTimer';
import Alli from '../../assets/Alli.png';

import { updateAudioPause, updateAudioPlayback, updatePlayAgain } from '../../store/schemas/audioSlice';
import { createHistory, updateEpisodeTime } from '../../httpcalls/historyThunk';

const Player = () => {
    const { seek } = useAudio();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const audioIndex = useSelector((state) => state.mainapp.audioSlice.activeAudioIndex);
    const audioItem = useSelector((state) => state.mainapp.audioSlice.audioList[audioIndex]);
    const audioPlayback = useSelector((state) => state.mainapp.audioSlice.audioPlayback);
    const audioPlaybackEnd = useSelector((state) => state.mainapp.audioSlice.audioPlaybackEnd);
    const pause = useSelector((state) => state.mainapp.audioSlice.pause);
    const playAgain = useSelector((state) => state.mainapp.audioSlice.playAgain);
    const [audioPlaybackControl, setAudioPlaybackControl] = useState(null);
    const storedEpisodePlayback = audioItem?.playback_time;

    useEffect(() => {
        if(audioItem && !playAgain && storedEpisodePlayback && audioPlayback !== storedEpisodePlayback) {
            dispatch(updateAudioPlayback(storedEpisodePlayback));
            seek(storedEpisodePlayback);
        }
        
        // Dispatch the createHistory thunk
        dispatch(createHistory({ listennotes_id: audioItem?.id, episode_title: audioItem?.title, thumbnail: audioItem?.thumbnail }));
    }, [audioItem, dispatch]);

    useEffect(() => {
        if(audioItem && playAgain && storedEpisodePlayback && audioPlayback !== storedEpisodePlayback) {
            dispatch(updateAudioPlayback(1));
            seek(1);
            dispatch(updatePlayAgain(false));
        }
    }, [audioItem, dispatch]);

    useEffect(() => {
        if(audioItem === null || audioItem === undefined) {
            navigate('/library');
        }
    }, [audioItem, audioIndex, navigate])

    const barRef = useRef(null);
    function convertPlaybackTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
        
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    
    // Adjust pause handling to ensure it doesn't directly trigger updates
    const handlePausePress = () => {
        dispatch(updateAudioPause(!pause));
        if (!pause) {
            // If we are pausing, dispatch update immediately
            dispatch(updateEpisodeTime({ listennotes_id: audioItem?.id, playback: audioPlayback }));
        }
    };

    const calculatePointPosition = (e, duration) => {
        const touchX = e.touches[0].clientX;
        const bar = e.currentTarget;
        const barStart = bar.getBoundingClientRect().left;
        const barWidth = barRef.current.getBoundingClientRect().width
        const touchPosition = touchX - barStart;
        const touchPercentage = touchPosition / barWidth; 
        const newTime = Math.floor(touchPercentage * duration); 

        if(newTime >= duration){
            setAudioPlaybackControl(duration)
        }
        else if(newTime <= 0){
            setAudioPlaybackControl(0)
        }
        else{
            setAudioPlaybackControl(newTime)
        }
    };

    const calculateTimePosition = (time) => {
        if(barRef.current == null) return 0
        const width = barRef.current.getBoundingClientRect().width
       return Math.round(time / audioPlaybackEnd *  width)
    }
    
    const rewindToStart = () => {
        seek(0);
        dispatch(updateAudioPlayback(0));
    };
    
    const rewind30Seconds = () => {
        const newTime = Math.max(audioPlayback - 30, 0);
        seek(newTime);
        dispatch(updateAudioPlayback(newTime));
    };

    const rewind5Seconds = () => {
        const newTime = Math.max(audioPlayback - 5, 0);
        seek(newTime);
        dispatch(updateAudioPlayback(newTime));
    };

    const forwardToEnd = () => {
        const newTime = audioPlaybackEnd;
        seek(newTime);
        dispatch(updateAudioPlayback(newTime));
    };
    
    const forward30Seconds = () => {
        const newTime = Math.max(audioPlayback + 30, 0);
        seek(newTime);
        dispatch(updateAudioPlayback(newTime));
    };

    const forward5Seconds = () => {
        const newTime = Math.max(audioPlayback + 5, 0);
        seek(newTime);
        dispatch(updateAudioPlayback(newTime));
    };

    
	return (
		<>
            <div className='page-fill-height'>
                <Header topLeft={[<HBack />]} />
                {audioItem !== null || audioItem !== undefined ?
                <div className="player-container">
                    <img src={audioItem?.thumbnail} alt={audioItem?.title} />
                    <h1><Marquee speed={30}>  {audioItem?.title} </Marquee></h1>
                    <PDescription html={audioItem?.description} title={audioItem?.title} clipLimit={240} />

                    <div onTouchStart={(e) => {calculatePointPosition(e, audioPlaybackEnd)}} onTouchMove={(e) => {calculatePointPosition(e, audioItem.audio_length_sec)}} onTouchEnd={() => {seek(audioPlaybackControl); setAudioPlaybackControl(null)}}   className='player-bar'>
                        <div className='player-default-bar'></div>
                        <div ref={barRef} className='player-markerbox-bar'></div>

                        <div className='player-moving-bar' style={audioPlaybackControl != null ? {width:calculateTimePosition(audioPlaybackControl) + 'px'} :  {width:calculateTimePosition(audioPlayback) + 'px'}}></div>
                        <span style={audioPlaybackControl != null ? {left:calculateTimePosition(audioPlaybackControl) + 'px'} :  {left:calculateTimePosition(audioPlayback) + 'px'}}></span>
                    </div>
                    <div className='player-timers'>
                        <p>{convertPlaybackTime(audioPlayback)}</p>
                        <p>{convertPlaybackTime(audioPlaybackEnd)}</p>
                    </div>
                    <div className='player-options'>
                        <PlaybackSpeed />
                        <SleepTimer />
                    </div>
                    {audioPlayback >0 && audioPlaybackEnd >0 ? ( 
                        <>
                            <div className='player-controls'>
                                <button onClick={rewind5Seconds} className='rewind_5s'><i className='material-icons'>replay_5</i></button>
                                <button onClick={rewind30Seconds} className='rewind_30s'><i className='material-icons'>replay_30</i></button>
                                <button onClick={rewindToStart} className='rewind'><i className='material-icons'>fast_rewind</i></button>
                                <button onClick={handlePausePress} className='playpause'>
                                        {pause ? <i className="material-icons">play_arrow</i> : <i className="material-icons">pause</i>}
                                    </button>
                                <button onClick={forwardToEnd} className='forward'><i className='material-icons'>fast_forward</i></button>
                                <button onClick={forward30Seconds} className='forward_30s'><i className='material-icons'>forward_30</i></button>
                                <button onClick={forward5Seconds} className='forward_5s'><i className='material-icons'>forward_5</i></button>
                            </div>
                            
                        </>
                    ) : (
                        <div className='player-control-loading'>
                            <h3>I'll have you're episode ready in just a second! </h3>
                            <img src={Alli} alt='Alli' />
                        </div>
                    )}
                </div>
                :
                    null

                }
           </div>
		</>
	);
};


export default Player;
