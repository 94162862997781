import React from "react";
import { useNavigate } from "react-router-dom";

const H_Back = () => {
	const navigate = useNavigate()
 
	return (
		<>
			<i onClick={() => {navigate(-1, {replace:true})}} className="material-icons">arrow_back</i>
		</>
	);
}

export default H_Back;
