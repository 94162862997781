import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Input, } from '@mantine/core';
import { createPlaylistWithPodcast, editPlaylistInfo } from '../../httpcalls/playlistThunks';


const L_Edit_Playlist = ({playlistId, currentTitle, close}) => {
	const dispatch = useDispatch()

	

	const submitCreation = (e) => {
		e.preventDefault()
		dispatch(editPlaylistInfo({playlistId:playlistId, playlistTitle:e.target.elements[0].value})).then(() => {
			close()
		})
	}


	

	return (


		<>
			<form onSubmit={(e) => {submitCreation(e)}}>
				<Input.Wrapper label="Playlist Name" size='lg'>
					<Input placeholder="My Amazing Playlist" size='md' defaultValue={currentTitle} radius={9} maxLength={30} />
				</Input.Wrapper>

				<button className='playlist-modal-action-button'> <i className='material-symbols-outlined'>library_add</i>  Confirm</button>
			</form>
		   
			
		
		</>
	);
};


export default L_Edit_Playlist;
