import {createSlice} from "@reduxjs/toolkit";

const initialState = () => ({
    searchQuery: {
        count: 0,
        next_offset: 0,
        total: 0,
        results: [],
    },
});

const searchSlice = createSlice({
    name: 'searchSlice',
    initialState: initialState(),
    reducers: {
        resetSearchSlice: state => initialState(),
        updateSearchQuery: (state, action) => {
            const { q, results } = action.payload;
            state.searchQuery = {
                q,
                results: state.searchQuery.results.concat(results),
            };
        },
    },
});

export const { resetSearchSlice, updateSearchQuery } = searchSlice.actions

export default searchSlice.reducer