import React from 'react';
import { Timeline } from '@mantine/core';
import X from '../../assets/X.png';
import NoT from '../../assets/NoTwitter.png';
import Apple from '../../assets/Apple.png';
import Alli from '../../assets/Alli.png';
import Heart from '../../assets/Heart.png';
import Android from '../../assets/Android.png';


const MilestoneOne = () => {
    return (
        <div className='milestoneContainer'>
            <div className='milestoneSubContainer'>
                <h1 className='completeMilestone'>Milestone</h1>
                <h2 className='completeMilestoneNumber'>1</h2>
                <div className='completeMilestoneText'>
                    <p>Web</p>
                    <p>App</p>
                    <p>Goes</p>
                    <p>Live</p>
                </div>
            </div>
            <a className='completeMilestoneLink' href='https://Alli.Media'>https://Alli.Media</a>
            <p className='completeFlavorText'>It's in Alpha. Don't be mean!</p>
            <p className='flavorText'>01/01/24: The web app version of Alli is live for public alpha testing!</p>
        </div>
    );
};

const MilestoneTwo = () => {
    return (
        <div className='milestoneContainer'>
            <div className='milestoneSubContainer'>
                <h1 className='completeMilestone'>Milestone</h1>
                <h2 className='completeMilestoneNumber'>2</h2>
                <div className='completeMilestoneText'>
                    <p>Alli</p>
                    <p>Hops</p>
                    <p>on</p>
                    <p>Tw-X</p>
                </div>
                <div className='flavorLogo'>
                    <img src={NoT} alt='' />
                    <img  src={X} alt=''/> 
                </div>
                
            </div>
            <p className='completeFlavorText'>(Because we can't call it Twitter anymore.)</p>
            <p className='flavorText'>01/14/24: Alli is now on Twitter/X! Talk with us at</p>
            <a className='milestoneLink' href='https://twitter.com/AlliDotMedia'>https://twitter.com/AlliDotMedia!</a>
        </div>
    );
};

const MilestoneThree = () => {
    return (
        <div className='milestoneContainer'>
            <div className='milestoneSubContainer'>
                <h1 className='milestone'>Milestone</h1>
                <h2 className='milestoneNumber'>3</h2>
                <div className='milestoneText'>
                    <p>Android</p>
                    <p>App</p>
                    <p>Goes</p>
                    <p>Live</p>
                </div>                
            </div>
            <div className='AlliRelationships'>
                <img src={Android} alt='' />
                <img src={Heart} alt=''/>
                <img src={Alli} alt='' /> 
            </div>
        </div>
    );
};

const MilestoneFour = () => {
    return (
        <div className='milestoneContainer'>
            <div className='milestoneSubContainer'>
                <h1 className='milestone'>Milestone</h1>
                <h2 className='milestoneNumber'>4</h2>
                <div className='milestoneText'>
                    <p>Apple</p>
                    <p>App</p>
                    <p>Goes</p>
                    <p>Live</p>
                </div>                
            </div>
            <div className='AlliRelationships'>
                <img src={Apple} alt='' />
                <img src={Heart} alt=''/>
                <img src={Alli} alt='' /> 
            </div>
        </div>
    );
};

const MilestoneFive = () => {
    return (
        <div className='milestoneContainer'>
            <div className='milestoneSubContainer'>
                <h1 className='milestone'>Milestone</h1>
                <h2 className='milestoneNumber'>5</h2>
                <div className='milestoneText'>
                    <p>Shhhh!</p>
                    <p>It's a</p>
                    <p>Secret</p>
                </div>                
            </div>
        </div>
    );
};

const Roadmap = () => {

    return (
        <div className='roadmapContainer'>
            <h1 className='roadmapHeader'> Where Alli's Headed in 2024!</h1>
            <div className='timelineContainer'>
                <Timeline active={1} bulletSize={24} lineWidth={3} >
                    <Timeline.Item title='Jan 1'>
                        <MilestoneOne />
                    </Timeline.Item>

                    <Timeline.Item title='Jan 14'>
                        <MilestoneTwo />
                    </Timeline.Item>

                    <Timeline.Item>
                        <MilestoneThree />
                    </Timeline.Item>

                    <Timeline.Item>
                        <MilestoneFour />
                    </Timeline.Item>

                    <Timeline.Item>
                        <MilestoneFive />
                    </Timeline.Item>

                </Timeline>
            </div>
        </div>
    );
};

export default Roadmap;