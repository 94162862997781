import React from 'react';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import Audio from './components/Audio/howler';

function MainApp() {
    const location = useLocation()



    return (
        <>

      <div style={location.pathname.includes('player') ? {display:"none"} : {}} id="bottom-navigation" className="bottom-nav-container">
        <div className="bottom-nav">

        <Link to={"/library"} replace={true} className={(location.pathname.includes("/library")) ? "active-nav" : "inactive-nav"}>
            <div className="bottom-nav-item">
            <i className="material-symbols-outlined">video_library</i>

              <p>My Stuff</p>
            </div>
          </Link>
        
        <Link to={"/discover"} replace={true} className={(location.pathname.includes("/discover")) ? "active-nav" : "inactive-nav"}>
            <div className="bottom-nav-item">
                <i className="material-icons">public</i>
              <p>Popular</p>
            </div>
          </Link>
  
          <Link to={"/search"} replace={true} className={(location.pathname.includes("/search")) ? "active-nav" : "inactive-nav"}>
            <div className="bottom-nav-item">
            <i className="material-icons">search</i>

              <p>Search</p>
            </div>
          </Link>
        </div>
        </div>


           {/* <Navbar /> */}
          <Audio>
              <Outlet />
          </Audio>

            <div style={location.pathname.includes('player') ? {display:"none"} : {}} className="nav-bar-ghost-spacer"></div>
        </>
    );
}

export default MainApp;
