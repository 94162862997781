import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    podcast:{},
    podcastPreviews: [],
})


const podcastSlice = createSlice({
    name: 'podcastSlice',
    initialState: initialState(),
    reducers: {
        resetPodcastSlice: state => initialState(),
        updatePodcast: (state, action) => {
            if (state.podcast.id === action.payload.id) {
                state.podcast.episodes = [
                    ...state.podcast.episodes,
                    ...action.payload.episodes
                ];
                state.podcast.next_episode_pub_date = action.payload.next_episode_pub_date;
            } else {
                state.podcast = action.payload
            }
        },
        resetPodcast: (state, action) => {
            state.podcast = {}
        },
        resetPodcastPreview: (state, action) => {
            state.podcastPreviews = []
        },
        updatePodcastPreviews: (state, action) => {
            state.podcastPreviews = action.payload
        },
    }
})

export const { resetPodcastSlice, updatePodcast, resetPodcast, resetPodcastPreview, updatePodcastPreviews } = podcastSlice.actions

export default podcastSlice.reducer