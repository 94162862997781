import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';
import { Modal, ScrollArea } from '@mantine/core';

import Header from '../../components/Header/header';
import H_Back from '../../components/Header/h_back';
import H_Title from '../../components/Header/h_title';
import P_Description from './p_description';
import audioWave from '../../assets/audio-wave.gif';
import P_Add_Playlist from './p_add_podcast';
import P_subscribe from './p_subscribe';

import { resetPodcastSlice } from '../../store/schemas/podcastSlice';
import { addAudio, resetAudioSlice, updatePlayAgain } from '../../store/schemas/audioSlice';
import { getPodcastById } from '../../httpcalls/podcastThunk';
import { getPodcastPreviews } from '../../httpcalls/podcastSubscriptionThunk';



const Podcasts = () => {
	const dispatch = useDispatch();
	const {id} =  useParams();
	const navigate = useNavigate();

	const podcast = useSelector((state) => state.mainapp.podcastSlice.podcast);
	const subscriptions = useSelector((state) => state.mainapp.podcastSlice.podcastPreviews);
	const audioIndex = useSelector((state) => state.mainapp.audioSlice.activeAudioIndex);
    const audioItem = useSelector((state) => state.mainapp.audioSlice.audioList[audioIndex]);
	const currentPlayingId	= audioItem?.id;

	const [opened, { open, close }] = useDisclosure(false);
	const [selectedPlaylist, setSelectedPlaylist] = useState(null);

	useEffect(() => {
			const fetchData = async () => {
				try {
					await Promise.all([
						dispatch(getPodcastById({id})),
						dispatch(getPodcastPreviews())
					]);
					console.log(podcast);
					console.log(subscriptions);
				} catch (error) {
					console.error('Error fetching data:', error);
				}
		};

		fetchData();

		return (() => {
			dispatch(resetPodcastSlice());
			window.scrollTo(0, 0)
		})
	}, [dispatch, id]);

	const startPlayer = (index) => {
		dispatch(resetAudioSlice())
		dispatch(addAudio({audioList:podcast.episodes, audioIndex: index}))
		navigate('/player')
	};

	const togglModal = (obj) => {
		setSelectedPlaylist(obj)
		open()
	};

	const loadMoreResults = () => {
		const newOffset = podcast.next_episode_pub_date;
		dispatch(getPodcastById({ id: id, offset: newOffset}));
	};

	//Format Publish Date into yyyy-mm-dd
	const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const day = date.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    };

	const audioWaveButton = () => {
		<div>
			{audioWave}
		</div>
	};

	const handleIsPlayingClick = () => {
		navigate('/player');
	};

	const playAgain = async (id, index) => {
		try {
			dispatch(resetAudioSlice());
			dispatch(addAudio({audioList:podcast.episodes, audioIndex: index}));
		} catch (error) {
			console.error("Failed to reset playback time", error);
		} finally {
			dispatch(updatePlayAgain(true));
			navigate('/player');
		}
	};

	//Format for audio_length_sec 
	const formatDuration = (time) => {
		if (time >= 60) {
			const minutes = Math.floor(time / 60);
			return `${minutes} min`;
		} else {
			return `${time} sec`;
		}
	};

	//Format for Partially listened episodes 
	const formatPartialDuration = (totalLength, playback) => {
		const time = totalLength - playback;

		if (time >= 60) {
			const minutes = Math.floor(time / 60);
			return `${minutes} mins left`;
		} else {
			return `${time} secs left`;
		}
	};

	return (
		<>
			<Header topLeft={[<H_Back />, <H_Title title={'Podcast'}/>]} />

			{podcast.title != undefined ? 
			<div className='podcast-container'>
				
					<Modal
						opened={opened}
						onClose={close}
						title={"Add To Playlist"}
						scrollAreaComponent={ScrollArea.Autosize}
						padding={'lg'}
						size={'sm'}
						className='podcast-modal'
					>
						{<P_Add_Playlist episode={selectedPlaylist} close={close} />}
					</Modal>
				<div className='podcast-header'>
					<img src={podcast.thumbnail} />
					<div className='podcast-header-text'>
						<h1>{podcast.title}</h1>
						<p>{podcast.publisher}</p>
						{<P_subscribe podcast={podcast} subscriptions={subscriptions} />}
					</div>
				</div>
				<div className='podcast-description'>
					<P_Description html={podcast.description} title={podcast.title} />
				</div>

				{podcast.episodes.map((episode, index) => {
					const dateObject = new Date(episode.pub_date_ms);
					const formattedDate = formatDate(dateObject);		
					const formattedDuration = formatDuration(episode.audio_length_sec);	
					const formattedPartialDuration = formatPartialDuration(episode.audio_length_sec, episode.playback_time);	
					const isListenedTo = episode.playback_time >= (episode.audio_length_sec *.8);
					const isUnfinished = episode.playback_time > 0 && episode.playback_time <= (episode.audio_length_sec * 0.9);
					
					return(
						<div>
							<div className='podcast-episode'>
								<div className='podcast-episode-header'>
									<div className='podcast-episode-header-text'>
										<h3>{episode.title}</h3>
										{formattedDate && (<p>Published: {formattedDate} </p>)}
									</div>
								</div>
								<P_Description html={episode.description} title={episode.title} />

								<div className='podcast-episode-buttons'>
								{currentPlayingId === episode.id ? (
									<img onClick={handleIsPlayingClick} src={audioWave} alt={episode.title}/>
								) : isListenedTo ? (
									<button onClick={(e) => {playAgain(episode.id, index)}}>
										<i className="material-symbols-outlined">restart_alt</i>
										<p>Play Again</p>
									</button>
								) : isUnfinished ? (
									<button onClick={(e) => {startPlayer(index)}}>
										<i className="material-symbols-outlined">play_circle</i>
										{formattedPartialDuration}
									</button>
								) : (
									<button onClick={(e) => {startPlayer(index)}}>
										<i className="material-symbols-outlined">play_circle</i>
										{formattedDuration}
									</button>
								)}
									<button onClick={(e) => {togglModal(episode)}}><i className="material-icons">playlist_add</i>Add to Playlist</button>
								</div>
							</div>
						</div>
					)
				})}
				<div className='load_more'>
					<button onClick={loadMoreResults}>Load More</button>
				</div>
			</div>
				:
				null
			}

		
		</>
	);
};


export default Podcasts;
