import { createAsyncThunk } from '@reduxjs/toolkit'
import { updatePodcastPreviews } from '../store/schemas/podcastSlice'


const server = process.env.REACT_APP_API_URL

export const createPodcastSubscription = createAsyncThunk(
    'podcast/createPodcastSubscription',
    async (data, { rejectWithValue }) => {
        try {
            let podcastInfoFormatted = {...data.podcastInfo}
            delete podcastInfoFormatted.episodes
            const response = await fetch(`${server}/podcast/createPodcastSubscription`, {
                method:'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'  
                },
                body: JSON.stringify({podcastTitle:data.podcastTitle, podcastInfo:podcastInfoFormatted})
            })
            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            return 'success'
        
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getPodcastPreviews = createAsyncThunk(
    'podcast/getPodcastPreviews',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/podcast/getPodcastPreviews`, {
                method:'GET',
                credentials: 'include',
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }

            thunkApi.dispatch(updatePodcastPreviews(results.message))
            return 'success'
        
        } catch (error) {
            console.log(error)
        }
    }
);

export const deletePodcast = createAsyncThunk(
    'podcast/deletePodcast',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/podcast/deletePodcast`, {
                method:'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'  
                },
                body: JSON.stringify({podcastId:data.podcastId})
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            await thunkApi.dispatch(getPodcastPreviews())
            return 'success'
        
        } catch (error) {
            console.log(error)
        }
    }
);

export const systemLatestPlaylist = createAsyncThunk (
    'podcast/systemLatestPlaylist',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/podcast/systemLatestPlaylist`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data), 
            });

            const results = await response.json();
            if (!response.ok || "error" in results) {
                return thunkApi.rejectWithValue(results);
            } 
            return results;
        } catch (error) {
            console.error('Fetch error:', error);
            return thunkApi.rejectWithValue(error);
        }
    }
);