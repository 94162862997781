import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { Modal, ScrollArea} from '@mantine/core';
import P_Create_Playlist from './p_create_playlist';
import { addToPlaylist, getPlaylists, getPlaylistsPreviews } from '../../httpcalls/playlistThunks';


const P_Add_Playlist = ({episode, close}) => {
	const dispatch = useDispatch()
	const [opened, { open:openCreatePlaylist , close:closeCreatePlaylist }] = useDisclosure(false);

    const playlists = useSelector((state) => state.mainapp.playlistSlice.playlistPreviews)
    let podcastInfo = useSelector((state) => state.mainapp.podcastSlice.podcast)
    const filteredPlaylists = playlists.filter((playlist) => playlist.title !== 'Subscription Latest');


	useEffect(() => {
        dispatch(getPlaylistsPreviews())
	}, [])

    const closeAll = () => {
        closeCreatePlaylist()
        close()
    }

    const playlistAdd = (id) => {
    
        dispatch(addToPlaylist({playlistId:id, episode:episode, podcastInfo})).then(() => {
            closeAll()
        })
    }


	

	return (


		<>
			<Modal
                opened={opened}
                onClose={closeCreatePlaylist}
                title={"Create Playlist"}
                scrollAreaComponent={ScrollArea.Autosize}
                padding={'lg'}
                size={'sm'}
                className='podcast-modal'
            >
                {<P_Create_Playlist episode={episode} close={closeAll} podcastInfo={podcastInfo} />}
            </Modal>

            {playlists.length > 0 ?
                    <>
                    {filteredPlaylists.map((playlist) => {
                        return(
                            <div onClick={() => {playlistAdd(playlist.playlist_id)}} style={{marginTop:'20px'}} className='library-action'>
                                <div className='library-img-container'>
                                    {(() => {
                                        let imgs = []
                                        for(let thumbnail of playlist.thumbnails){
                                            if(playlist.thumbnails.length == 3){
                                                if(imgs.length == 2){
                                                    break
                                                }
                                            }
                                                imgs.push(<img src={thumbnail} />)
                                        }
                                        return imgs
                                    })()}

                                </div>
                                <div className='library-text-container'>
                                    <h1>{playlist.title}</h1>
                                    <p>{
                                        (() => {
                                            let combinedString = ""
                                            for(let i = 0; i < playlist.titles.length; i++){
                                                if(i == playlist.titles.length - 1){
                                                    combinedString +=  playlist.titles[i]
                                                }
                                                else{
                                                    combinedString += playlist.titles[i] + " · "
                                                }
                                            }
                                            return combinedString
                                        })()
                                        
                                        
                                    }</p>
                                </div>
                            </div>
                        )
                    })}
                    
                    
                    </>
                :

                <p className='playlist-modal-not-found'>No Playlists Found</p>
            }


            <button onClick={openCreatePlaylist} className='playlist-modal-action-button'> <i className='material-symbols-outlined'>library_add</i>  Add New Playlist</button>

		
		</>
	);
};


export default P_Add_Playlist;
