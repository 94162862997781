import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import './App.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

import App from './App';
import { Provider } from 'react-redux';
import { createReduxStore } from './store/store';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

const store = createReduxStore()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <MantineProvider withGlobalStyles withNormalizeCSS>
    <Provider store={store}>
        <BrowserRouter>
        <Notifications />
          <App />
        </BrowserRouter>
      </Provider>
  </MantineProvider>
 
);

