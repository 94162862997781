import React, { useMemo, useEffect } from 'react';
import { createAvatar } from '@dicebear/core';
import { initials } from '@dicebear/collection';
import { useDispatch, useSelector } from "react-redux";

import Header from '../../components/Header/header';
import HBack from '../../components/Header/h_back';
import HTitle from '../../components/Header/h_title';
import UserHistory from './userHistory';

import { logout } from "../../httpcalls/authThunk";

const UserAccount = () => {

    const userInfo = useSelector((state) => state.auth.userInfo);
    const display_name = useSelector((state) => state.auth.userInfo?.display_name);
	const dispatch = useDispatch();

    const imageSrc = useMemo(() => {
		const avatar = createAvatar(initials, {
			seed:display_name
		});
		return avatar.toDataUriSync(); 
	}, [display_name])

    return (
        <>
            <Header topLeft={[<HBack />, <HTitle title='Profile'/>]} />

            <div className='profile-container'>
                <div className='profile-header'>
                    <div className='profile-image'>
                        <img src={imageSrc} className='profile-avatar'  alt="profile icon" />
                    </div>
                    <div className='profile-text'>
                        <h2>{display_name}</h2>
                        <p>{userInfo.email}</p>
                    </div>
                    <div className='episode-history'>
                        <h3>Episode History:</h3>
                        <UserHistory />
                    </div>
                    <div className='signout'>
                        <button onClick={() => {dispatch(logout())}}>Sign Out</button>
                    </div>
                </div>

                

                
                
            </div>
        </>
    );
};

export default UserAccount;