import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { Howl } from 'howler';
import { useDispatch, useSelector } from 'react-redux';
import { overwriteAudioIndex, updateAudioPlayback, updateAudioPlaybackEnd, updateAudioPlaybackSpeed, updateEpisodePlayback } from '../../store/schemas/audioSlice';
import { updateEpisodeTime } from '../../httpcalls/historyThunk';

const AudioContext = createContext();

export const useAudio = () => useContext(AudioContext);

const Audio = ({ children }) => {
  const [howl, setHowl] = useState(null);
  const dispatch = useDispatch();
  const audioIndex = useSelector((state) => state.mainapp.audioSlice.activeAudioIndex);
  const audioList = useSelector((state) => state.mainapp.audioSlice.audioList[audioIndex]);
  const audioPlayback = useSelector((state) => state.mainapp.audioSlice.audioPlayback);
  const playPause = useSelector((state) => state.mainapp.audioSlice.pause);
  const storedEpisodePlayback = audioList?.playback_time;
  const [intervalId, setIntervalId] = useState(null); // Store the interval ID
  const playAgain = useSelector((state) => state.mainapp.audioSlice.playAgain);
  const audioDurationRef = useRef(0);

  const startAudio = (src) => {
    if (howl) {
      howl.unload(); 
    }

    const newHowl = new Howl({
      src: [src],
      html5: true, 
      onplay: () => {
        const id = setInterval(() => {
          const currentTime = newHowl.seek();
            dispatch(updateAudioPlayback(Math.round(currentTime))); 
        }, 1000); 
        setIntervalId(id);
      },
      onload: () => {
        dispatch(updateAudioPlaybackEnd(Math.round(newHowl._duration)));
        audioDurationRef.current = newHowl.duration(); // Store the total duration
      },
      onend: () => {
        dispatch(updateEpisodeTime({ listennotes_id: audioList.id, playback: audioList.audio_length_sec }));
        dispatch(updateEpisodePlayback({ id: audioList.id, playbackTime: audioList.audio_length_sec }));
        newHowl.unload()
        dispatch(overwriteAudioIndex(audioIndex + 1));
        clearInterval(intervalId);
      },
      onpause: () => {
        clearInterval(intervalId); 
      },
      onstop: () => {
        clearInterval(intervalId); 
      }
    });

    if (!playAgain && storedEpisodePlayback) {
      newHowl.once('load', () => {
        newHowl.seek(storedEpisodePlayback);
      });
    }

    if(playPause === false) {
        newHowl.play();
    }
    setHowl(newHowl);
  };

  const playAudio = () => {
    if (howl) {
        howl.play();
      }
  };

  const pauseAudio = () => {
    if (howl) {
      howl.pause();
    }
  };

  const stopAudio = () => {
    if (howl) {
      howl.stop();
    }
  };

  const seekAudio = (timeInSeconds) => {
    if (howl) {
      howl.seek(timeInSeconds);
      dispatch(updateAudioPlayback(timeInSeconds));
    }
  };

  const unloadAudio = () => {
    if (howl) {
      howl.unload();
    }
  };

  const changePlaybackSpeed = (speed) => {
    dispatch(updateAudioPlaybackSpeed(speed));
    if (howl) {
      howl.rate(speed);
    }
  };

  useEffect(() => {
    if(playPause){
        pauseAudio()
    }
    else{
        playAudio()
    }
  }, [playPause])

  useEffect(() => {
    return () => {
      if (howl) {
        howl.unload();
      }
    };
  }, [howl]);


  useEffect(() => {
    if (audioList != null) {
        startAudio(audioList.audio)
    }
  }, [audioIndex])

  useEffect(() => {
    return () => {
      // Clear the interval when component unmounts
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  const contextValue = {
    start: startAudio,
    play: playAudio,
    pause: pauseAudio,
    stop: stopAudio,
    seek: seekAudio,
    unload: unloadAudio,
    changePlaybackSpeed,
  };

  return (
    <AudioContext.Provider 
    value={contextValue}>
      {children}
    </AudioContext.Provider>
  );
};

export default Audio;