import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './schemas/authSlice';
import discoverSlice from './schemas/discoverSlice';
import podcastSlice from './schemas/podcastSlice';
import audioSlice from './schemas/audioSlice';
import playlistSlice from './schemas/playlistSlice';
import podcastlistSlice from './schemas/podcastlistSlice';
import historySlice from './schemas/historySlice';
import searchSlice from './schemas/searchSlice';

export const createReduxStore = (preloadedState) => {
  return configureStore({
    reducer: {
      auth: authSlice,
       mainapp: combineReducers({
        discoverSlice: discoverSlice,
        podcastSlice: podcastSlice,
        audioSlice: audioSlice,
        playlistSlice: playlistSlice,
        podcastlistSlice: podcastlistSlice,
        historySlice: historySlice,
        searchSlice: searchSlice,
      })
    },
    preloadedState
  });
};

