import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPodcastSubscription, deletePodcast } from '../../httpcalls/podcastSubscriptionThunk';
import { notifications } from '@mantine/notifications';


const P_subscribe = ({ podcast, subscriptions }) => {
    const dispatch = useDispatch();
    const [isSubscribed, setIsSubscribed] = useState(subscriptions.some(subscriptions => subscriptions.title === podcast.title));

    useEffect(() => {
		console.log(isSubscribed);
            setIsSubscribed(subscriptions.some(subscriptions => subscriptions.title === podcast.title));
            console.log('Podcast', podcast);
            console.log('Subscriptions', subscriptions);
            console.log(isSubscribed);
    }, [subscriptions, podcast]);

    const handleSubscription = (podcast) => {
        dispatch(createPodcastSubscription({podcastTitle:podcast.title, podcastInfo:podcast}))
		.unwrap()
		.then((message) => {
			notifications.show({
				title: 'Success',
				message: 'Successfully subscribed to podcast.',
				color: 'blue',
				autoClose: 5000,
				position: 'top-right',
			});
			setIsSubscribed(true);
		})
		
		.catch((rejectedValueOrSerializedError) => {
			notifications.show({
				title: 'Error',
				message: (rejectedValueOrSerializedError.message || 'Failed to Subscribe to Podcast.'),
				color: 'red',
				autoClose: 5000,
			});
		});
    };

	// Find the subscription object that matches the current podcast
	const matchingSubscription = subscriptions.find(subscription => subscription.title === podcast.title);


	const handleDeleteSubscription = (podcast) => {
		if (matchingSubscription) {
			const podcastIdToDelete = matchingSubscription.podcast_id;

			dispatch(deletePodcast({podcastId: podcastIdToDelete}))
			.unwrap()
			.then((message) => {
				notifications.show({
					title: 'Success',
					message: 'Successfully Deleted Subscription.',
					color: 'blue',
					autoClose: 5000,
				});
				setIsSubscribed(false);
			})
			.catch((rejectedValueOrSerializedError) => {
				notifications.show({
					title: 'Error',
					message: (rejectedValueOrSerializedError.message || 'Failed to Delete Subscription.'),
					color: 'red',
					autoClose: 5000,
				});
			});
		}
        
    };

    return (
        <>
            {isSubscribed ? (
                <div className='unsubscribe'>
                    <button onClick={(e) => {handleDeleteSubscription(subscriptions)}}><i className='material-icons'>bookmark_remove</i>Unsubscribe</button>
                </div>
            ): (
                <div className='subscribe'>
                    <button onClick={(e) => {handleSubscription(podcast)}}><i className='material-icons'>bookmark_add</i>Subscribe</button>
                </div>
            )}
        </>
    );

};

export default P_subscribe;