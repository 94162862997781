import React, { useMemo } from "react";
import { createAvatar } from '@dicebear/core';
import { initials } from '@dicebear/collection';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../httpcalls/authThunk";
import { useNavigate } from "react-router-dom";
import { Menu } from "@mantine/core";

const H_Profile = ({signOut}) => {
    const display_name = useSelector((state) => state.auth.userInfo?.display_name);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	

    const imageSrc = useMemo(() => {
		const avatar = createAvatar(initials, {
			seed:display_name
		});
		return avatar.toDataUriSync(); 
	}, [display_name]);

	const handleProfileClick = () => {
		navigate('/profile');
	};



	return (
		signOut ?
		<>
		<Menu shadow="md" width={200} >
			<Menu.Target>
					<img src={imageSrc} alt="profile icon" />
			</Menu.Target>

			<Menu.Dropdown>
				<Menu.Item onClick={handleProfileClick}>
					<div className="profile-logout">
						<i className="material-icons">person</i>
						<span>Profile</span>
					</div>
				</Menu.Item>
				<Menu.Item onClick={() => {dispatch(logout())}}>
					<div className="profile-logout">
						<i className="material-icons">logout</i>
						<span>Sign Out</span>
					</div>
				</Menu.Item>

			</Menu.Dropdown>

		</Menu>
		</>
		:
		<>
			<img src={imageSrc} className='profile-avatar'  alt="profile icon" />
		</>
	);
}

export default H_Profile;
