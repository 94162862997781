import React from 'react';
import { useSelector } from 'react-redux';
import { useAudio } from '../../components/Audio/howler';

const PlaybackSpeed = () => {
    const { changePlaybackSpeed } = useAudio();

    const playbackSpeed = useSelector((state) => state.mainapp.audioSlice.audioPlaybackSpeed);

    return (
        <div className='playback-speed'>
                <label for="playbackSpeed"><i className='material-icons'>speed</i></label>
                <select id='playbackSpeed' onChange={(e) => changePlaybackSpeed(parseFloat(e.target.value))} value={playbackSpeed}>
                    <option value={.5}>0.5x</option>
                    <option value={.75}>0.75x</option>
                    <option value={1}>1x</option>
                    <option value={1.25}>1.25x</option>
                    <option value={1.5}>1.5x</option>
                    <option value={2}>2x</option>
                </select>
        </div>
    );
};

export default PlaybackSpeed;