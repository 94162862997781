import React from 'react';import Header from "../../components/Header/header";
import H_Title from '../../components/Header/h_title';
import H_Back from '../../components/Header/h_back';
import { Link } from 'react-router-dom';
import H_Settings from '../../components/Header/h_settings';

const PrivacyPolicy = () => {
  return (
    <div className='privacy_policy'>
        <Header topLeft={[<H_Back />, <H_Title title={"Privacy Policy"} />]} topRight={<H_Settings/>}  />
        <div className='privacy_policy_container'>
            <h2>
                Effective Date: 11/06/2023
            </h2>
            <p>
                Welcome to Alli.Media ("we," "our," or "us")! Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy is designed to inform you about the data we collect, how we use it, and your choices regarding your information – and all in a format that is easy to read and understand.
            </p>
            <div>
                <h3>
                    1. Information We Collect
                </h3>
                <p>
                    <span>a.</span> User-Generated Content: When you use our podcast player app and website, we collect information you voluntarily provide, such as your name, email address, and feedback. <br />
                    <span>b.</span> Activity Data: We collect data about your interactions with the app and website, such as the podcasts you listen to, your listening history, the duration of your sessions, and your device information. <br />
                    <span>c.</span> Analytics: We use third-party analytics tools to collect information about how you interact with our app and website, such as page views, session duration, and demographic information (e.g., age, gender). <br />
                    <span>d.</span> Cookies and Similar Technologies: We use cookies and similar technologies to collect data about your usage patterns and preferences. You can manage cookie preferences through your browser settings.
                </p>    
            </div>
            <div>
                <h3>
                    2. How We Use Your Information
                </h3>
                <p>
                    We collect and use your information for the following purposes: <br />
                    <span>a.</span> Improving Our Services: We use your data to enhance the functionality and quality of our podcast player app and website. <br />
                    <span>b.</span> Marketing and Promotions: Your information may be used to send you relevant content, promotional materials, and updates about our app and website. <br />
                    <span>c.</span> Research and Analytics: We analyze user data to better understand trends, preferences, and demographics, helping us improve our products and services. <br /> 
                    <span>d.</span> Monetization: We may use your data to deliver personalized advertisements and sponsored content to support the free offerings of our app and website. <br />
                </p>
            </div>
            <div>
                <h3>
                    3. Data Sharing
                </h3>
                <p>
                    We do not sell, rent, or trade your personal contact information to third parties without your explicit consent. However, we may share data with trusted partners for the purposes outlined in this policy.
                </p>
            </div>
            <div>
                <h3>
                    4. Your Choices
                </h3>
                <p>
                    <span>a.</span> Opt-Out: You have the right to opt-out of marketing communications at any time by following the instructions in the emails you receive or by contacting us. <br />
                    <span>b.</span> Access and Correction: You may access, correct, or update your personal contact information by contacting us. <br />
                </p>
            </div>
            <div>
                <h3>
                    5. Security
                </h3>
                <p>
                    We take reasonable precautions to protect your personal information from unauthorized access or disclosure.
                </p>
            </div>
            <div>
                <h3>
                    6. Changes to this Privacy Policy
                </h3>
                <p>
                    We may update this Privacy Policy to reflect changes in our practices. Any updates will be posted on our website, and the effective date will be revised accordingly.
                </p>
            </div>
            <div>
                <h3>
                    7.  Clarifications in Language
                </h3>
                <p>
                All references in this document in the plural ("we," "our," or "us") or possessive plural are to be understood as Alli.Media and no specific member thereof. 
                </p>
                <p>
                The "such as" phrase used throughout this document is used to introduce examples, and it implies that the examples provided are not an exhaustive list but rather illustrative of the broader category or concept being explained.
                </p>
            </div>
            <div>
                <h3>
                    8. Contact Us
                </h3>
                <p>
                    If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please <Link to='/contact'>contact us</Link>.
                </p>
            </div>
            <div className='privacy_policy_signoff'>
                <h4>
                    Thank you for using Alli.Media! We appreciate your trust in us and are committed to protecting your privacy.
                </h4>
            </div>
        </div>
    </div>
  );
};

export default PrivacyPolicy;