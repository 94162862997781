import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    history:[],
      
})


const historySlice = createSlice({
    name: 'historySlice',
    initialState: initialState(),
    reducers: {
        resetHistorySlice: state => initialState(),
        updateHistory: (state, action) => {
            console.log(action.payload)
            state.history = action.payload.history
        },
    }
})

export const { resetHistorySlice, updateHistory } = historySlice.actions

export default historySlice.reducer